import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from "react-redux";
import "./forum.css";
import { toast } from 'react-toastify';
import {
    API_ERROR
} from "../../config/error-message";
import { getForumList, addAnswer, getForumDetails } from "../../crud/qa-forum-crud";

const monthArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const defaultImage = require('../../../images/imgAvtr.png');
const adminProfileImage = require('../../../images/logo-blue.png');

const Forum = (props) => {
    const [forumList, updateForumList] = useState([]);
    const [myAnswer, updateMyAnswer] = useState("");
    const [selectedQuestionId, updateSelectedQuestionId] = useState("");
    const [disablePage, toggleDisablePage] = useState(false);
    const { isAuthorized, authData } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.user != null,
            authData: auth.user
        }),
        shallowEqual
    );

    const getFullDate = (date) => {
        const d = new Date(date);
        return d.getDate() + ' ' + monthArr[d.getMonth()] + ' ' + d.getFullYear();
    };

    const getAllForumList = async () => {
        toggleDisablePage(true);
        const forumResults = await getForumList();
        toggleDisablePage(false);
        if (forumResults.data.errorCode) {
            toast.error(API_ERROR[forumResults.data.errorCode]);
            return;
        }
        if (forumResults.data.data && forumResults.data.data.errorCode) {
            toast.error(API_ERROR[forumResults.data.data.errorCode]);
            return;
        }
        console.log("forumResults ", forumResults)
        if (forumResults.data.data && forumResults.data.data.data && forumResults.data.data.data.length) {
            let forumObj = {};
            const forumArr = [];
            forumResults.data.data.data.forEach(each => {
                forumObj = {};
                forumObj._id = each._id;
                forumObj.question = each.question;
                forumObj.selected = false;
                forumObj.noOfComments = each.answers.length;
                forumObj.answers = [];
                forumArr.push({ ...forumObj });
            });
            updateForumList([...forumArr]);
        }
    };

    const selectAnswer = (id) => {
        const localProf = [...forumList];
        localProf.forEach(each => {
            if (each._id === id) {
                if (selectedQuestionId === each._id) {
                    each.selected = true;
                } else {
                    each.selected = !each.selected;
                }

            } else {
                each.selected = false;
            }

        });
        updateForumList([...localProf]);
    };

    const getQuestionDetails = async (id) => {
        toggleDisablePage(true);
        const localQuestionDetails = await getForumDetails(id);
        toggleDisablePage(false);
        if (localQuestionDetails.data.errorCode) {
            toast.error(API_ERROR[localQuestionDetails.data.errorCode]);
            return;
        }
        if (localQuestionDetails.data.data && localQuestionDetails.data.data.errorCode) {
            toast.error(API_ERROR[localQuestionDetails.data.data.errorCode]);
            return;
        }
        console.log("localQuestionDetails ", localQuestionDetails.data);
        const localProf = [...forumList];
        localProf.forEach(each => {
            if (each._id === id) {
                let answerObj = {};
                if (localQuestionDetails.data.data && localQuestionDetails.data.data.answers && localQuestionDetails.data.data.answers.length) {
                    each.answers = [];
                    each.askedByName = localQuestionDetails.data.data.askedBy.personalInfo.name || '';
                    each.askedByProfilePhoto = localQuestionDetails.data.data.askedBy.personalInfo.profilePicture || '';
                    localQuestionDetails.data.data.answers.forEach(item => {
                        answerObj = {};
                        answerObj.answerText = item.answer;
                        answerObj._id = item._id;
                        answerObj.date = item.time;
                        answerObj.name = "";
                        answerObj.profilePicture = "";
                        if (item.answeredBy && item.answeredBy.personalInfo) {
                            answerObj.name = item.answeredBy.personalInfo.name;
                            answerObj.profilePicture = item.answeredBy.personalInfo.profilePicture;
                            answerObj.userType = item.answeredBy.userType;
                        }
                        console.log("answerObj ", answerObj)
                        each.answers.push({ ...answerObj });
                        each.noOfComments = each.answers.length;
                    })
                }
                console.log("each ", each, id)
            }
        });
        console.log("localProf ", localProf)
        updateForumList([...localProf]);
        selectAnswer(id);
    };

    const chooseAnswer = (item) => {
        if (!item.selected) {
            getQuestionDetails(item._id);
            updateSelectedQuestionId(item._id);
        } else {
            updateSelectedQuestionId("");
            const localProf = [...forumList];
            localProf.forEach(each => {
                if (each._id === item._id) {
                    each.selected = false;
                }

            });
            updateForumList([...localProf]);
        }
    };

    useEffect(() => {
        getAllForumList();
    }, []);

    const giveAnswer = async () => {
        console.log("myAnswer ", myAnswer);
        if (myAnswer.trim().length) {
            toggleDisablePage(true);
            const answerResult = await addAnswer(selectedQuestionId, { answer: myAnswer.trim() });
            toggleDisablePage(false);
            console.log("answerResult ", answerResult);
            if (answerResult.data.errorCode) {
                toast.error(API_ERROR[answerResult.data.errorCode]);
                return;
            }
            if (answerResult.data.data && answerResult.data.data.errorCode) {
                toast.error(API_ERROR[answerResult.data.data.errorCode]);
                return;
            }

            updateMyAnswer("");
            getQuestionDetails(selectedQuestionId);
        }
    };

    const openMyQa = () => {
        if (isAuthorized) {
            props.history.push("/myQA");
        } else {
            toast.info("Please login to ask a question");
        }
    };

    return (
        <div className={`row forum ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
            <div className="col-lg-12 p-0">
                <div className="row" style={{ margin: 0 }}>
                    <div className={`col-6 categories-heading`}>
                        <h4 className={`mb-0`}>Forum</h4>
                    </div>
                    <div className={`col-6 text-right language-dropdown-wrapper`}>
                        <button type="button" className="btn btn-link" onClick={openMyQa}>My Q & A</button>
                    </div>
                </div>

                <div className="col-12">
                    <div className="row professional-near-you-wrapper" style={{ margin: 0 }}>
                        {forumList.length ?
                            forumList.map((professional, index) => {
                                return <div className={`col-md-6 col-xs-12 professional-wrapper
                                ${index % 2 === 0 ? 'forum-more-768-pr-15' : ''}
                                ${index !== forumList.length - 1 && index !== forumList.length - 2 ? 'forum-more-768-pb-15' : ''}
                                ${index !== forumList.length - 1 ? 'forum-less-768-pb-15' : ''}
                                `} key={index}>
                                    <div className="row professional-wrapper-row p-0" style={{ margin: 0 }}>
                                        <div className="col-12 p-0">
                                            <div className={`professional-designation p-15 ${professional.selected ? 'show-answers-bottom' : ''}`} onClick={() => chooseAnswer({ ...professional })}>
                                                {professional.selected ?
                                                    professional.askedByProfilePhoto ?
                                                        <div style={{ backgroundImage: "url(" + professional.askedByProfilePhoto + ")" }} className="background-image-professional mb-0" /> :
                                                        <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="background-image-professional mb-0" /> : null}
                                                {!professional.selected ? <>
                                                    {professional.question} <span> ({professional.noOfComments} comment{professional.noOfComments > 1 ? "s" : ""}) </span> </> :
                                                    <div className="question-asked-details">
                                                        <div className="question-commentedby">{professional.askedByName}</div>
                                                        {professional.question} <span> ({professional.noOfComments} comment{professional.noOfComments > 1 ? "s" : ""}) </span>
                                                    </div>}
                                                {/* <img src={require('../../../images/favourite2_Fill.svg')} alt="make favorite" /> */}
                                            </div>
                                            {!professional.selected ?
                                                null :
                                                <div className="professional-location answer">
                                                    <div className="row answers-wrapper" style={{ margin: 0 }}>
                                                        <div className="col-12 p-0">
                                                            {professional.answers.length ?
                                                                professional.answers.map((answer, index) => {
                                                                    return <div className="row" style={{ margin: 0 }} key={index}>
                                                                        {answer.userType !== 3 ?
                                                                            answer.profilePicture ?
                                                                                <div style={{ backgroundImage: "url(" + answer.profilePicture + ")" }} className="background-image-professional" /> :
                                                                                <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="background-image-professional" /> :
                                                                            <div style={{ backgroundImage: "url(" + adminProfileImage + ")" }} className="background-image-professional" />}
                                                                        <div className=" answer-text">
                                                                            <div className="answer-commentedby">{answer.userType !== 3 ? answer.name : 'Parentcraft Admin'}
                                                                                <span className="answer-date"> {getFullDate(answer.date)} </span>
                                                                            </div>
                                                                            <div>{answer.answerText.split('\n').map((item, key) => {
                                                                                return <span key={key}>{item}<br /></span>
                                                                            })}</div>
                                                                        </div>
                                                                    </div>
                                                                })
                                                                : null}
                                                        </div>
                                                    </div>
                                                    <div className="row" style={{ margin: 0 }}>
                                                        {authData && authData.personalInfo && authData.personalInfo.profilePicture ?
                                                            <div style={{ backgroundImage: "url(" + authData.personalInfo.profilePicture + ")" }} className="background-image-professional" /> :
                                                            <div style={{ backgroundImage: "url(" + defaultImage + ")" }} className="background-image-professional" />}
                                                        <div className="message-input">
                                                            <div className="input-group">
                                                                <textarea className="form-control" placeholder="Enter Message" value={myAnswer} onChange={(e) => updateMyAnswer(e.target.value)} disabled={(authData && authData.personalInfo && !authData.personalInfo.name)}></textarea>
                                                                <button disabled={!myAnswer.trim().length || (authData && authData.personalInfo && !authData.personalInfo.name)} className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={giveAnswer}><img src={require("../../../images/message-send.svg")} alt="send message" /></button>
                                                            </div>
                                                            {authData && authData.personalInfo && !authData.personalInfo.name ?
                                                                <div className="col-12 p-0 text-info">
                                                                    Please update your name from profile
                                                        </div> : null}
                                                        </div>

                                                    </div>
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                            })
                            : <div className="text-center well col-12 color-black">
                                No Questions Available!
                    </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Forum;
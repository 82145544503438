import React, { useState, useEffect, useRef } from "react";

const Participant = ({ participant, isMe }) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [videoOn, toggleVideo] = useState(true);
  const [audioOn, toggleAudio] = useState(true);
  // const [muted, toggleMute] = useState(false);

  const videoRef = useRef();
  const audioRef = useRef();

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    // participant.on('trackDisabled', track => {
    //   console.log("track ",track)
    //   // hide or remove the media element related to this track
    // });

    const trackSubscribed = (track) => {
      console.log("in here ", track.mediaStreamTrack, track.mediaStreamTrack.id)
      track.on("disabled", (track) => {
        if (track.kind === "audio") {
          toggleAudio(false);
        }
      })
      track.on("enabled", (track) => {
        if (track.kind === "audio") {
          toggleAudio(true);
        }
      })
      if (track.kind === "video") {
        toggleVideo(true);
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      console.log("in here ", track.mediaStreamTrack, track)
      track.on("disabled", (track) => {
        console.log("mlkmaldml----------- ", track)
      })
      if (track.kind === "video") {
        toggleVideo(false);
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  // const muteAudio = () => {
  //   console.log("participant.audioTracks ",participant.audioTracks)
  //   participant.audioTracks.forEach(track => {
  //     console.log("track ",track, audioRef)
  //     toggleMute(true);
  //     // track.disable();
  //   });
  // };

  return (
    !isMe ?
      // <div className="participant">
      //   {/* <h3>{participant.identity}</h3> */}
      //   <video ref={videoRef} autoPlay={true} />
      //   <audio ref={audioRef} autoPlay={true} muted={muted} />
      //   {/* <div> */}
      //   {/* <button id="muteAudio" onClick={muteAudio} style={{left: "0"}}>Mute</button> */}
      //   {/* <button onClick={stopVideo} style={{left: "50px"}}>Stop</button> */}
      //   {/* </div> */}
      // </div>
      <>
        {videoOn ?
          <>
            <video ref={videoRef} autoPlay={true} />
            {!audioOn ? <i className="fas fa-microphone-slash remote-muted"></i> : null}
          </>
          : <>
            <div className="no-video-preview">Professional closed video 
            {!audioOn ? <i className="fas fa-microphone-slash remote-muted"></i> : null}</div>
          </>
        }
        <audio ref={audioRef} autoPlay={true} muted={false} />
      </>
      :
      <>
        <video ref={videoRef} autoPlay={true} id="my-video" />
        <audio ref={audioRef} autoPlay={true} muted={false} id="my-audio" />
      </>
  );
};

export default Participant;

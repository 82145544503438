import axios from "./axios.interceptor";
import baseUrl from "./api.util";

export const GET_PROFILE = `${baseUrl}/account/vendor/profile`;
export const UPDATE_BANK = `${baseUrl}/account/vendor/profile/update-bank`;
export const UPDATE_PROFILE = `${baseUrl}/account/vendor/profile/update`;
export const LOCATION = `${baseUrl}/account/vendor/address/`;
export const GET_CATEGORIES = `${baseUrl}/account/vendor/category/list`;
export const UPDATE_PROFILE_IMAGE = `${baseUrl}/account/vendor/profile/update-profile-pic`;
export const ADD_IMAGE = `${baseUrl}/account/vendor/profile/add-photo`;
export const REMOVE_IMAGE = `${baseUrl}/account/vendor/profile/remove-photo`;
export const HELPSUPPORT = `${baseUrl}/account/vendor/support`;

export const getProfile = async () => {
  const res = await axios.get(GET_PROFILE);
  return res;
}

export const getCategories = async () => {
  const res = await axios.get(GET_CATEGORIES);
  return res;
}

export const updateBank = async (requestBody) => {
    const res = await axios.patch(UPDATE_BANK, requestBody);
    return res;
}

export const updateProfile = async (requestBody) => {
    const res = await axios.patch(UPDATE_PROFILE, requestBody);
    return res;
}

export const updateProfilePhoto = async (imageFile) => {
    const formData = new FormData();
    formData.append('profilePicture', imageFile);
    const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
    };
    const res = await axios.put(UPDATE_PROFILE_IMAGE, formData, config);
    return res;
}

export const addPhoto = async (imageFile) => {
    const formData = new FormData();
    formData.append('photo', imageFile);
    const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
    };
    const res = await axios.put(ADD_IMAGE, formData, config);
    return res;
}

export const getLocationList = async () => {
    const res = await axios.get(`${LOCATION}?skip=0&limit=0`);
    return res;
}

export const addLocation = async (requestBody) => {
    const res = await axios.post(LOCATION, requestBody);
    return res;
}

export const updateLocation = async (requestBody) => {
    const res = await axios.put(`${LOCATION}/${requestBody._id}`, requestBody);
    return res;
}

export const removeLocation = async (id) => {
    const res = await axios.delete(`${LOCATION}/${id}`);
    return res;
}

export const removeImage = async (id) => {
    const res = await axios.delete(`${REMOVE_IMAGE}/${id}`);
    return res;
}

export const helpSupport = async (requestBody, imageFile) => {
    const formData = new FormData();
    if (imageFile) {
      formData.append('image', imageFile);
    }
    if (requestBody) {
      for (let i in requestBody) {
        formData.append(i, requestBody[i]);
      };
    }
    const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
    };
    const res = await axios.post(`${HELPSUPPORT}`, formData, config);
    return res;
  }


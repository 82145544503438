export const API_SUCCESS = {
    UPDATE_PROFILE: "Profile updated successfully",
    ADD_LOCATION: "Location added successfully",
    UPDATE_LOCATION: "Location updated successfully",
    REMOVE_LOCATION: "Location removed successfully",
    REMOVE_IMAGE: "Image removed successfully",
    ADD_IMAGE: "Image added successfully",
    UPDATE_PROFILE_IMAGE: "Profile picture updated successfully",
    UPDATE_BANK: "Bank details updated successfully",
    ADD_SERVICE: "Service added successfully",
    UPDATE_SERVICE: "Service updated successfully",
    REMOVE_SERVICE: "Service removed successfully",
    LOGIN_SUCCESS: "Login successfully",
    APPOINTMENT_COMPLETED: "Appointment completed successfully",
    CANCEL_SUCCESS: "Appointment cancelled successfully",
    COMPLAIN_SUBMITTED: "Complain/ Suggestion submitted successfully",
    SLOT_BLOCKED: "Slot blocked successfully",
    MARK_HOLIDAY: "The day is marked as holiday",
    SLOT_UNBLOCKED: "Slot unblocked successfully"
};
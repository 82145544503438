import React, { useEffect, useState, useCallback } from 'react';
import "./service.css";
import { months, timeArrConst, BookingNumberStatus } from "../../config/constants";
import { getRevenue } from "../../crud/booking.crud";
import { toast } from 'react-toastify';
import { API_ERROR } from "../../config/error-message";
const monthArr = [...months];
const timeArr = [...timeArrConst];

const Revenue = (props) => {
    const [disablePage, toggleDisablePage] = useState(false);
    const [selectedMonth, updateSelectedMonth] = useState("");
    const [yearArr, updateYearArr] = useState([]);
    const [selectedYear, updateSelectedYear] = useState("");
    const [revenueDetails, updateRevenueDetails] = useState({});
    const [bookingArray, updateBookingArray] = useState([]);
    const [loadingRevenueDetails, toggleRevenueDetails] = useState(true);

    const getFullDateFormat = (date) => {
        const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
        const month = (date.getMonth() + 1).toString().length === 1 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const getStartTime = (times) => {
        times.sort((a, b) => { return a - b });
        return timeArr[times[0] - 1];
    };

    const getEndTime = (times) => {
        times.sort((a, b) => { return a - b });
        return timeArr[times[times.length - 1]] ? timeArr[times[times.length - 1]] : timeArr[0];
    };

    const getAllRevenue = useCallback(async (reqBody) => {
        toggleDisablePage(true);
        toggleRevenueDetails(true);
        const bookingResult = await getRevenue(reqBody);
        console.log("bookingResult ", bookingResult);

        toggleDisablePage(false);
        toggleRevenueDetails(false);
        if (bookingResult.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.errorCode]);
            return;
        }
        if (bookingResult.data.data && bookingResult.data.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.data.errorCode]);
            return;
        }

        const bookingObj = {
            netPayable: 0,
            totalServiceCharge: 0,
            orgFee: 0,
            status: "Due",
            paymentDate: ""
        };
        const localBookingArr = [];
        let localBookObj = {};
        if (bookingResult.data.data && bookingResult.data.data.bookingData && bookingResult.data.data.bookingData.bookings &&
            bookingResult.data.data.bookingData.bookings.length) {
            bookingResult.data.data.bookingData.bookings.forEach(each => {
                bookingObj.netPayable += each.transactionDetails.vendorDue;
                bookingObj.orgFee += each.transactionDetails.organiserDue;

                localBookObj = {
                    name: each.bookingDetais.bookingForName,
                    date: each.bookingDetais.date,
                    startTime: getStartTime(each.bookingDetais.slots),
                    endTime: getEndTime(each.bookingDetais.slots),
                    status: each.bookingDetais.status,
                    serviceFee: each.transactionDetails.organiserDue ? each.transactionDetails.organiserDue : each.transactionDetails.vendorDue,
                    serviceType: each.transactionDetails.organiserDue ? 'Organization': 'Individual'
                };
                localBookingArr.push({ ...localBookObj });

            });
        }
        if (bookingResult.data.data && bookingResult.data.data.payouts && bookingResult.data.data.payouts.date && bookingResult.data.data.payouts.amount) {
            bookingObj.status = "Paid";
            bookingObj.paymentDate = bookingResult.data.data.payouts.date;
        }
        bookingObj.totalServiceCharge = bookingObj.netPayable + bookingObj.orgFee;
        updateRevenueDetails({ ...bookingObj });
        updateBookingArray([...localBookingArr]);
    }, []);

    useEffect(() => {
        const date = new Date();
        const currentMonth = date.getMonth() + 1;
        const currentYear = date.getFullYear();
        updateSelectedMonth(currentMonth.toString());

        const localYearArr = [];
        for (let i = 2021; i <= currentYear; i++) {
            localYearArr.push({
                value: i.toString(),
                text: i.toString()
            });
        }
        updateYearArr([...localYearArr]);
        updateSelectedYear(currentYear.toString());
        getAllRevenue({ month: currentMonth, year: currentYear });
    }, [getAllRevenue]);

    const selectMonth = (e) => {
        updateSelectedMonth(e.target.value);
        getAllRevenue({ month: Number(e.target.value), year: Number(selectedYear) });
    };

    const selectYear = (e) => {
        updateSelectedMonth(e.target.value);
        getAllRevenue({ month: Number(selectedMonth), year: Number(e.target.value) });
    };

    return (
        <div className={`row problem-area ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
            <div className="col-12">
                <div className="col-12 categories-heading pl-0">
                    <h4 className={`mb-0`}>Revenue</h4>
                </div>
                <div className="row professional-near-you-wrapper" style={{ margin: 0 }}>
                    <div className="col-6 pl-0">
                        <label for="exampleFormControlInput1" className="form-label">Select Month</label>
                        <select className="form-select form-control" aria-label="Service Time" value={selectedMonth} onChange={selectMonth}>
                            {monthArr.map((loc, index) => {
                                return <option key={index} value={loc.value}>{loc.text}</option>
                            })}
                        </select>
                    </div>
                    <div className="col-6 pr-0">
                        <label for="exampleFormControlInput1" className="form-label">Select Year</label>
                        <select className="form-select form-control" aria-label="Service Time" value={selectedYear} onChange={selectYear}>
                            {yearArr.map((loc, index) => {
                                return <option key={index} value={loc.value}>{loc.text}</option>
                            })}
                        </select>
                    </div>
                </div>
                {Object.keys(revenueDetails).length && bookingArray.length ?
                    <div className="row ml-0 mr-0 mt-3 mb-3">
                        <div className="col-sm-6 col-xs-12 revenue-left-section">
                            <div className="row m-0 professional-near-you-wrapper">
                                <div className="col-12 p-0">
                                    <h4 className={`mb-0`}>Invoice</h4>
                                </div>
                                <div className="col-8 pl-0 fee-label mt-2">
                                    Total Service Charge
                                </div>
                                <div className="mt-2 col-4 pr-0 fee-text text-right">
                                    Rs. {revenueDetails.totalServiceCharge.toFixed(2)}
                                </div>
                                <div className="mt-2 col-8 pl-0 fee-label">
                                    Organization Fee
                                </div>
                                <div className="mt-2 col-4 pr-0 fee-text text-right">
                                    Rs. {revenueDetails.orgFee.toFixed(2)}
                                </div>
                                <div className="mt-2 col-8 pl-0 fee-label bor-top pt-2">
                                    Net Payable
                                </div>
                                <div className="mt-2 col-4 pr-0 fee-text text-right bor-top pt-2">
                                    Rs. {revenueDetails.netPayable.toFixed(2)}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12 revenue-right-section">
                            <div className="row m-0 professional-near-you-wrapper">
                                <div className="col-12 p-0">
                                    <h4 className={`mb-0`}>Payment Status</h4>
                                </div>
                                <div className="col-12 text-center mt-2 fee-text">
                                    {revenueDetails.status}
                                </div>
                                <div className="mt-2 col-8 pl-0 fee-label">
                                    Payment Date
                                </div>
                                <div className="mt-2 col-4 pr-0 fee-text text-right">
                                    {revenueDetails.paymentDate ? getFullDateFormat(new Date(revenueDetails.paymentDate)) : 'N/A'}
                                </div>
                                <div className="mt-2 col-12 p-0">
                                    Note: Amount will be credited to your bank account provided within 3 working days.
                            </div>
                            </div>
                        </div>
                        <div className="col-12 p-0 mt-3">
                            <div className="row m-0 professional-near-you-wrapper">
                                <div className="col-12 p-0">
                                    <h4 className={`mb-0`}>Bookings</h4>
                                </div>
                                <div className="col-12 p-0 mt-3">
                                    <div className="row m-0">
                                        {bookingArray.length ?
                                            bookingArray.map((booking, ind) => {
                                                return <div key={ind} className={`col-sm-6 col-xs-12 professional-wrapper
                                                ${ind % 2 === 0 ? 'problem-more-768-pr-15-for-2' : ''}
                                                ${ind !== bookingArray.length - 1 && ind !== bookingArray.length - 2 && ind !== bookingArray.length - 3 ? 'problem-more-768-pb-15' : ''}
                                                ${ind !== bookingArray.length - 1 && ind !== bookingArray.length - 2 ? 'problem-more-768-pb-15-for-2' : ''}
                                                ${ind !== bookingArray.length - 1 ? 'problem-more-768-pb-15-for-1' : ''}
                                                `}>
                                                    <div className="row m-0 professional-near-you-wrapper">
                                                        <div className="col-6 pl-0 fee-label mt-2">
                                                            Name
                                                        </div>
                                                        <div className="mt-2 col-6 pr-0 fee-text text-right">
                                                            {booking.name}
                                                        </div>
                                                        <div className="mt-2 col-6 pl-0 fee-label">
                                                            Date
                                                        </div>
                                                        <div className="mt-2 col-6 pr-0 fee-text text-right">
                                                            {getFullDateFormat(new Date(booking.date))}
                                                        </div>
                                                        <div className="mt-2 col-6 pl-0 fee-label">
                                                            Time
                                                        </div>
                                                        <div className="mt-2 col-6 pr-0 fee-text text-right">
                                                            {booking.startTime} - {booking.endTime}
                                                        </div>
                                                        <div className="mt-2 col-6 pl-0 fee-label">
                                                            Service Type
                                                        </div>
                                                        <div className="mt-2 col-6 pr-0 fee-text text-right">
                                                            {booking.serviceType}
                                                        </div>
                                                        <div className="mt-2 col-6 pl-0 fee-label">
                                                            Service Fee
                                                        </div>
                                                        <div className="mt-2 col-6 pr-0 fee-text text-right">
                                                            Rs. {booking.serviceFee.toFixed(2)}
                                                        </div>
                                                        <div className="mt-2 col-6 pl-0 fee-label">
                                                            Booking Status
                                                        </div>
                                                        <div className="mt-2 col-6 pr-0 fee-text text-right">
                                                            {BookingNumberStatus[booking.status]}
                                                        </div>
                                                    </div>
                                                </div>
                                            }) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : <div className="text-center well col-12 p-0 mt-3 color-black">
                        <div className="professional-near-you-wrapper">
                            {!loadingRevenueDetails ? 'No Details Found!' : 'loading...'}
                        </div>
                    </div>}
            </div>
        </div>
    )
}

export default Revenue;
import axios from "./axios.interceptor";
import baseUrl from "./api.util";

export const BOOKINGS = `${baseUrl}/account/vendor/booking`;
export const JOIN_CALL = `${baseUrl}/account/vendor/booking/video/token`;
export const REVENUE = `${baseUrl}/account/vendor/revenue/list`;

const getISOString = (date) => {
    return new Date(new Date(new Date(new Date(new Date(date).setHours(5)).setMinutes(30)).setSeconds(0)).setMilliseconds(0)).toISOString();
};

export const getBookings = async (date) => {
  const res = await axios.post(`${BOOKINGS}/list?skip=0&limit=0`,{date: getISOString(date)});
  return res;
}

export const completeBooking = async (id, data) => {
  const res = await axios.post(`${BOOKINGS}/complete/${id}`,data);
  return res;
}

export const joinCall = async (requestBody) => {
  const res = await axios.post(`${JOIN_CALL}`, requestBody);
  return res;
}

export const cancelBook = async (id) => {
  const res = await axios.put(`${BOOKINGS}/cancel/${id}`);
  return res;
}

export const blockSlotAPI = async (requestBody) => {
  const res = await axios.post(`${BOOKINGS}/block-slot`, requestBody);
  return res;
}

export const unblockSlotAPI = async (id) => {
  const res = await axios.put(`${BOOKINGS}/cancel-block-slot/${id}`);
  return res;
}

export const getRevenue = async (requestBody) => {
  const res = await axios.post(`${REVENUE}?skip=0&limit=0`,requestBody);
  return res;
}


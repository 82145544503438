/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import HomePage from "../pages/home/HomePage";
import ErrorsPage from "../pages/errors/ErrorsPage";
import LogoutPage from "../pages/auth/Logout";
import { helpSupport } from "../crud/profile.crud";
import { LayoutContextProvider } from "../../_metronic";
import * as routerHelpers from "../router/RouterHelpers";
import { API_ERROR } from "../config/error-message";
import AuthPage from "../pages/auth/AuthPage";
import { toast } from 'react-toastify';
import "./navigation.css";
import * as authDock from "../store/ducks/auth.duck";
import { urls } from "../config/constants";
import { API_SUCCESS } from "../config/success-message";
import LogoutModal from "./logout-modal";

const Routes = withRouter(({ history, ...props }) => {
  const [profileOpened, toggleProfile] = useState(false);
  const [isMenuCollapsed, toggleCollapse] = useState(false);
  const [displayHelpModal, toggleHelpModal] = useState(false);
  const [disablePage, toggleDisablePage] = useState(false);
  const [helpObject, updateHelpObject] = useState({
    description: "",
    type: 1
  });
  const [uploadedHelpImage, updateHelpImage] = useState("");
  const lastLocation = useLastLocation();
  const [currentPage, updateCurrentPage] = useState("/dashboard");
  const [displayLogoutModal, toggleLogoutModal] = useState(false);
  routerHelpers.saveLastLocation(lastLocation);
  const { isAuthorized, menuConfig, userLastLocation, authData } = useSelector(
    ({ auth, urls, builder: { menuConfig } }) => ({
      menuConfig,
      isAuthorized: auth.user != null,
      authData: auth.user,
      userLastLocation: routerHelpers.getLastLocation()
    }),
    shallowEqual
  );

  const openMenu = (bool) => {
    toggleProfile(false);
  };

  const openProfile = (bool) => {
    toggleProfile(bool);
  };

  const openCollapseMenu = (bool) => {
    toggleCollapse(bool);
    toggleProfile(false);
  };

  const openPage = (path) => {
    history.push(path);
    openMenu(false);
    openProfile(false);
    updateCurrentPage(path);
    if (window.innerWidth < 768) {
      openCollapseMenu(false);
    }
  };

  useEffect(() => {
    window.onclick = (el) => {
      if (el.target.className.indexOf('dropdown-toggle') <= -1) {
        openMenu(false);
        openProfile(false);
      }
    }
  });

  const openFileUploader = () => {
    if (document.getElementById("helpImage")) {
      document.getElementById("helpImage").click();
    }
  };

  const logout = () => {
    toggleLogoutModal(false);
    props.logout();
  };

  useEffect(() => {
    for (const i in urls) {
      if (history.location.pathname.indexOf(i) > -1) {
        updateCurrentPage(urls[i]);
      }
    }
  }, [isAuthorized, authData, history.location.pathname]);

  const changeHelpDescription = (text) => {
    const localHelp = { ...helpObject };
    localHelp.description = text;
    updateHelpObject({ ...localHelp });
  };

  const changeHelpType = (type) => {
    const localHelp = { ...helpObject };
    localHelp.type = type;
    updateHelpObject({ ...localHelp });
  };

  const uploadHelpImage = async () => {
    if (document.getElementById("helpImage")) {
      let imageFile = document.getElementById("helpImage");
      if (imageFile.files[0]) {
        if (imageFile.files[0].type.indexOf("image") > -1) {
          updateHelpImage(imageFile.files[0].name);
        } else {
          toast.error("Please upload image file");
          return;
        }
      }
    }
  };

  const resetHelpForm = () => {
    updateHelpObject({
      description: "",
      type: 1
    });
    updateHelpImage("");
    let imageFile = "";

    imageFile = document.getElementById("helpImage");
    imageFile.value = '';
  };

  const submitHelp = async () => {
    console.log("helpObj ", helpObject);
    let imageFile = "";

    imageFile = document.getElementById("helpImage");
    if (imageFile.files[0]) {
      console.log("imageFile.files[0] ", imageFile.files[0]);
      if (imageFile.files[0].type.indexOf("image") > -1) {
        updateHelpImage(URL.createObjectURL(imageFile.files[0]));
      } else {
        toast.error("Please upload image file");
        return;
      }
    }
    //  return;
    toggleDisablePage(true);
    const result = await helpSupport(helpObject, imageFile.files[0]);

    toggleDisablePage(false);
    if (result.data.errorCode) {
      toast.error(API_ERROR[result.data.errorCode]);
      return;
    }
    if (result.data.data && result.data.data.errorCode) {
      toast.error(API_ERROR[result.data.data.errorCode]);
      return;
    }
    toast.success(API_SUCCESS.COMPLAIN_SUBMITTED);
    toggleHelpModal(false);
    resetHelpForm();
  };

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <Switch>
        {!isAuthorized
          ? (<AuthPage />)
          : (<Redirect from="/auth" to={userLastLocation} />)}

        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={LogoutPage} /> {!isAuthorized
          ? (<Redirect to="/auth/login" />)
          : (
            <>
              <div>
                <div className={`col-md-12 custom-navigation-bar pad-0-below-768 ${disablePage ? "pointer-none" : ""}`}>
                  <nav className="navbar navbar-expand-md navbar-light custom-left-navigation-bar pad-0-below-768">
                    <span className="navbar-brand">
                      <img src={require("../../images/nav-logo.png")} className="nav-logo" alt="nav logo" style={{ cursor: "pointer" }} onClick={() => openPage("/dashboard")} />
                    </span>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation" onClick={() => openCollapseMenu(!isMenuCollapsed)}>
                      <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`collapse navbar-collapse ${isMenuCollapsed ? 'show' : ''}`} id="navbarNavDropdown">
                      <ul className="navbar-nav ml-auto margin-top-less-than-768">
                        <li className={`nav-item border-top-less-than-768 ${currentPage === '/dashboard' ? 'active' : ''}`}>
                          <span className="nav-link" onClick={() => openPage('/dashboard')}>HOME <span className="sr-only">(current)</span></span>
                        </li>
                        <li className={`nav-item border-top-less-than-768 ${currentPage === '/service' ? 'active' : ''}`}>
                          <span className="nav-link" onClick={() => openPage('/service')}>SERVICES</span>
                        </li>
                        <li className={`nav-item border-top-less-than-768 ${currentPage === '/scheduler' ? 'active' : ''}`}>
                          <span className="nav-link" onClick={() => openPage('/scheduler')}>SCHEDULER</span>
                        </li>
                        <li className={`nav-item border-top-less-than-768 ${currentPage === '/forum' ? 'active' : ''}`}>
                          <span className="nav-link" onClick={() => openPage('/forum')}>FORUM</span>
                        </li>
                        <li className={`nav-item border-top-less-than-768 show-less-than-768 ${currentPage === '/revenue' ? 'active' : ''}`}>
                          <span className="nav-link" onClick={() => openPage('/revenue')}>REVENUE</span>
                        </li>
                        <li className={`nav-item border-top-less-than-768 show-less-than-768`}>
                          <span className="nav-link" onClick={() => toggleHelpModal(true)}>Help & Support</span>
                        </li>
                        <li className="nav-item border-top-less-than-768 show-less-than-768">
                          <span className="nav-link" onClick={() => toggleLogoutModal(true)}>LOG OUT</span>
                        </li>
                        <li className={`nav-item border-top-less-than-768 show-more-than-768 dropdown ${profileOpened ? 'show' : ''}`}>
                          <span className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => openProfile(!profileOpened)}>
                            Hi {authData.personalInfo ? authData.personalInfo.name.split(" ")[0] : ""}
                          </span>
                          <div className={`dropdown-menu ${profileOpened ? 'show dropdown-menu-opened' : ''}`} aria-labelledby="navbarDropdownMenuLink">
                            <span className="dropdown-item" onClick={() => openPage('/revenue')}>REVENUE</span>
                            <span className="dropdown-item" onClick={() => toggleHelpModal(true)}>Help & Support</span>
                            <span className="dropdown-item" onClick={() => toggleLogoutModal(true)}>LOG OUT</span>
                          </div>
                        </li>
                      </ul>
                      
                    </div>
                  </nav>
                </div>
              </div>
              <div className={`row header-body-wrapper ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
                <div className="col-lg-12 main-page-wrapper">
                  <HomePage userLastLocation={userLastLocation} />
                </div>
              </div>
              <LogoutModal displayLogoutModal={displayLogoutModal}
                toggleLogoutModal={toggleLogoutModal} logout={logout} />
              <div className={`modal login-modal ask-question-modal fade ${displayHelpModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Help & Support</h5>
                    </div>
                    <div className="modal-body">
                      <div>
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="help" id="help1" value="Issue" checked={helpObject.type === 1} onChange={() => changeHelpType(1)} />
                          <label className="form-check-label" htmlFor="help1">
                            Issue
                                  </label>
                        </div>
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="help" id="help2" value="Suggestions" checked={helpObject.type === 2} onChange={() => changeHelpType(2)} />
                          <label className="form-check-label" htmlFor="help2">
                            Suggestions
                                  </label>
                        </div>
                      </div>
                      <div>
                        <div className="form-group question-input">
                          <textarea className="form-control" id="exampleFormControlTextarea1" rows="4" placeholder={`Describe the ${helpObject.type === 1 ? 'issue' : 'suggestion'} you have ${helpObject.type === 1 ? 'encountered' : ''}`} value={helpObject.description} onChange={e => changeHelpDescription(e.target.value)}></textarea>
                        </div>
                      </div>
                      <div>
                        Add image (upload a screenshot of the specific screen on which you {helpObject.type === 1 ? 'are facing an issue' : 'have a suggestion'}.)
                              </div>
                      <div className="file-uploader" onClick={openFileUploader}>
                        <input type="file" id="helpImage" hidden onChange={uploadHelpImage} />
                        <i className="fa fa-upload" aria-hidden="true"></i>
                      </div>
                      {uploadedHelpImage ?
                        <div className="mt-3 mb-3">{uploadedHelpImage}</div> : null}

                      <div className="col-12 login-button">
                        <button type="button" className="btn btn-primary" disabled={!helpObject.description.trim().length} onClick={submitHelp}>Submit</button>
                        <button type="button" className="btn btn-link" onClick={() => { resetHelpForm(); toggleHelpModal(false) }}>Cancel</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="spacer"></div> */}
              <footer className={`footer ${disablePage ? "pointer-none" : ""}`}>
                <ul className="nav col-md-7 col-xs-12">
                  <li className="nav-item">
                    <span className="nav-link" onClick={() => openPage('/faq')}>FAQs</span>
                  </li>
                  <li className="nav-item">
                    <span className="nav-link" onClick={() => window.open("https://www.parentcraftindia.com")}>About Us</span>
                  </li>
                  <li className="nav-item">
                    <span className="nav-link" onClick={() => window.open("https://www.parentcraftindia.com/termsandconditions")}>Terms of Use</span>
                  </li>
                  <li className="nav-item">
                    <span className="nav-link" onClick={() => window.open("https://www.parentcraftindia.com/privacy-policies")}>Privacy Policy</span>
                  </li>
                </ul>
                <div className="col-md-5 col-xs-12 copyright-text">
                  © 2021 Parentcraft India. All rights reserved.
                </div>
              </footer>
            </>
          )}
      </Switch>
    </LayoutContextProvider>
  );
});

export default injectIntl(
  connect(
    null,
    authDock.actions
  )(Routes)
);

import axios from "./axios.interceptor";
import baseUrl from "./api.util";

export const GET_QA_LIST = `${baseUrl}/account/vendor/qa/my-activity`;
export const GET_FORUM_LIST = `${baseUrl}/common/qa`;
export const QA_URL = `${baseUrl}/account/vendor/qa`;
export const GET_FAQ_LIST = `${baseUrl}/vendor/common/faqs`;

export const getQAList = async () => {
    const res = await axios.get(`${GET_QA_LIST}?skip=0&limit=0`);
    return res;
}

export const getForumList = async () => {
  const res = await axios.get(`${GET_FORUM_LIST}?skip=0&limit=0`);
  return res;
}

export const getForumDetails = async (id) => {
  const res = await axios.get(`${GET_FORUM_LIST}/${id}/details`);
  return res;
}

export const addAnswer = async (id, requestBody) => {
  const res = await axios.post(`${QA_URL}/${id}/answer`, requestBody);
  return res;
}

export const getFAQList = async () => {
  const res = await axios.get(`${GET_FAQ_LIST}?skip=0&limit=0`);
  return res;
}
import axios from "./axios.interceptor";
import baseUrl from "./api.util";

export const SERVICE = `${baseUrl}/account/vendor/service/`;
export const ORGANIZATIONS = `${baseUrl}/common/organisers`;

export const getServices = async () => {
  const res = await axios.get(`${SERVICE}?skip=0&limit=0`);
  return res;
}

export const addService = async (requestBody) => {
  const res = await axios.post(SERVICE, requestBody);
  return res;
}

export const getOrganizations = async () => {
  const res = await axios.get(`${ORGANIZATIONS}?skip=0&limit=0`);
  return res;
}

export const getLocationsForOrganization = async (id) => {
  const res = await axios.get(`${ORGANIZATIONS}/${id}/addresses`);
  return res;
}

export const updateService = async (requestBody) => {
  const res = await axios.put(`${SERVICE}/${requestBody._id}`, requestBody);
  return res;
}

export const removeService = async (id) => {
  const res = await axios.delete(`${SERVICE}/${id}`);
  return res;
}



import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "./DashboardNew";
import Service from "../admins/service";
import Scheduler from "../admins/scheduler";
import Forum from "../admins/forum";
import FAQ from "../admins/faq";
import MyQA from "../admins/my-qa";
import Video from "../videoCall/VideoChat";
import Revenue from "../admins/revenue";
import { LayoutSplashScreen } from "../../../_metronic";

export default function HomePage() {
  // useEffect(() => {
  //   console.log('Home page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/service" component={Service} />
        <Route path="/scheduler" component={Scheduler} />
        <Route path="/revenue" component={Revenue} />
        <Route path="/forum" component={Forum} />
        <Route path="/faq" component={FAQ} />
        <Route path="/myQA" component={MyQA} />
        <Route path="/video-call/:bookingId" component={Video} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}

import React, { useCallback, useEffect, useState } from "react";
import Participant from "./Participant";
var Video = require('twilio-video');

const Room = ({ roomName, room, handleLogout, handleComponentOut }) => {
  const [participants, setParticipants] = useState([]);
  const [videoOn, toggleVideo] = useState(true);
  const [audioOn, toggleAudio] = useState(true);
  const [participantLeft, toggleParticipantLeft] = useState(false);

  useEffect(() => {
    const participantConnected = (participant) => {
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };

    const participantDisconnected = (participant) => {
      toggleParticipantLeft(true);
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      );
    };

    room.on("participantConnected", participantConnected);
    room.on("participantDisconnected", participantDisconnected);
    room.on('trackAdded', track => {
      // track.on('enabled', () => {

      // });
      track.on('disabled', (track) => {
        console.log("hhhhhhhhhh ", track)
      });
    });
    room.participants.forEach(participantConnected);
    return () => {
      room.off("participantConnected", participantConnected);
      room.off("participantDisconnected", participantDisconnected);
    };
  }, [room]);

  const remoteParticipants = participants.map((participant) => (
    <Participant key={participant.sid} participant={participant} isMe={false} />
  ));

  const stopVideo = useCallback(() => {
    // const video = document.querySelector('.local-participant video');

    // if (window.localStream) {
    //   console.log("in if");
    //   window.localStream.getVideoTracks()[0].stop();
    // } else {
      // A video's MediaStream object is available through its srcObject attribute
      // const mediaStream = video.srcObject;
      // console.log("in else");
      // // Through the MediaStream, you can get the MediaStreamTracks with getTracks():
      // const tracks = mediaStream.getTracks();
      // console.log("video ",tracks)
      // // Tracks are returned as an array, so if you know you only have one, you can stop it with: 
      // tracks[0].stop();
    // }


    // const mediaStream = video.srcObject;
    // const tracks = mediaStream.getTracks();
    // console.log("video ",tracks)
    // tracks[0].stop();

    // console.log("room.localParticipant ",room.localParticipant.videoTracks.values())
    // const track = [...room.localParticipant.videoTracks.values()][0].track;
    // console.log("track ", track)
    // room.localParticipant.unpublishTrack(track);
    if ([...room.localParticipant.videoTracks.values()][0]) {
      const track = [...room.localParticipant.videoTracks.values()][0].track;
      track.stop();
      track.detach();
      room.localParticipant.unpublishTrack(track);
    }
    // room.localParticipant.videoTracks.forEach(track => {
    //   track.track.disable();
    // });
    toggleVideo(false);

  }, [room.localParticipant]);

  const stopAudio = useCallback(() => {
    const audio = document.querySelector('.local-participant audio');

    if (window.localStream) {
      window.localStream.getAudioTracks()[0].stop();
    } else {
      const mediaStream = audio.srcObject;

      // Through the MediaStream, you can get the MediaStreamTracks with getTracks():
      const tracks = mediaStream.getTracks();

      // Tracks are returned as an array, so if you know you only have one, you can stop it with: 
      tracks[0].stop();
    }

    room.localParticipant.audioTracks.forEach(track => {
      // console.log("track ",track)
      track.track.disable();
    });

    // console.log("room ",room)
    // const track = [...room.localParticipant.audioTracks.values()][0].track;
    // console.log("track ", track)
    // room.localParticipant.unpublishTrack(track);
    toggleAudio(false);

  }, [room.localParticipant]);

  useEffect(() => {
    return () => {
      handleComponentOut();
      stopVideo();
      stopAudio();
    }
  }, [handleComponentOut, stopVideo, stopAudio]);

  const startVideo = () => {
    // const video = document.querySelector('.local-participant video');
    // navigator.mediaDevices.getUserMedia({
    //   video: true
    // })
    // .then(stream => {
    //   // window.localStream = stream;
    //   video.srcObject = stream;
    // });

    Video.createLocalVideoTrack({video: { width: 1200 }}).then(track => {
      const localMediaContainer = document.querySelector('.local-participant');
      if (document.querySelector('.local-participant video')) {
        document.querySelector('.local-participant video').remove();
      }
      localMediaContainer.appendChild(track.attach());
      return room.localParticipant.publishTrack(track);
    });
    // room.localParticipant.videoTracks.forEach(track => {
    //   track.track.enable();
    // });
    toggleVideo(true);
  }

  const startAudio = () => {
    // const audio = document.querySelector('.local-participant audio');
    // navigator.mediaDevices.getUserMedia({
    //   audio: true
    // })
    // .then(stream => {
    //   window.localStream = stream;
    //   audio.srcObject = stream;
    // });

    // Video.createLocalVideoTrack().then(track => {
    //   const localMediaContainer = document.querySelector('audio');
    //   localMediaContainer.appendChild(track.attach());
    //   return room.localParticipant.publishTrack(track);
    // });
    room.localParticipant.audioTracks.forEach(track => {
      track.track.enable();
    });
    toggleAudio(true);
  }

  return (
    <div className="room">
      <div className="local-participant">
        {room ? (
          <Participant
            key={room.localParticipant.sid}
            participant={room.localParticipant}
            isMe={true}
          />
        ) : (
          ""
        )}
      </div>
      {/* <h3>Remote Participants</h3> */}
      <div className={`remote-participants ${!participants.length ? 'no-participant-wrapper': ''}`}>
        {participants.length ? remoteParticipants :
          participantLeft ? <>Customer has left the meeting.</> :
          <>Customer has not joined the meeting yet. Please wait...</>
        }
      </div>
      <div className="col-12 text-center video-control-button-wrapper">
        {!videoOn ?
        <button className="video-toggle" onClick={startVideo}>
          <i className="fas fa-video-slash"></i>
        </button> :
        <button className="audio-toggle" onClick={stopVideo}>
          <i className="fas fa-video"></i>
        </button> }
        {!audioOn ?
        <button className="video-toggle" onClick={startAudio}>
          <i className="fas fa-microphone-slash"></i>
        </button> :
        <button className="audio-toggle" onClick={stopAudio}>
          <i className="fas fa-microphone"></i>
        </button> }
        <button className="leave-call" onClick={handleLogout}>
          <i className="fas fa-phone"></i>
        </button>
      </div>
    </div>
  );
};

export default Room;

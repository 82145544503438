import React, { useEffect, useState, useCallback } from 'react';
import "./dashboard.css"
import * as auth from "../../store/ducks/auth.duck";
import { toast } from 'react-toastify';
import {
    getProfile,
    updateBank,
    updateProfile,
    getLocationList,
    addLocation,
    updateLocation,
    removeLocation,
    getCategories,
    updateProfilePhoto,
    addPhoto,
    removeImage
} from "../../crud/profile.crud";
import DateFnsUtils from '@date-io/date-fns';
import {
    TimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import RemoveModal from "../admins/remove-modal";
import {
    REQUIRED_ERROR,
    INVALID_FROM_TO_DATE,
    CHOOSE_ERROR,
    INVALID_EMAIL,
    CHOOSE_CATEGORY_ERROR,
    API_ERROR,
    INVALID_WEBSITE,
    INVALID_MONTH,
    INVALID_LOCATION
} from "../../config/error-message";
import { API_SUCCESS } from "../../config/success-message";
import {
    timeArrConst,
    daysConstant
} from "../../config/constants";
import GalleryModal from "../admins/gallery-modal";

const initialMenuOptions = [{
    option: "Gallery",
    selected: true
}, {
    option: "Bank Details",
    selected: false
}, {
    option: "Address Book",
    selected: false
}];

var dayName = ["Mon", "Tue", "Wed", "Thurs", "Fri", "Sat", "Sun"];

const days = [...daysConstant];

const timeArr = [...timeArrConst];

const initialBankDetailsError = {
    accountHolderName: "",
    bankName: "",
    accountNumber: "",
    ifscCode: "",
    branchName: "",
    gstNumber: ""
};

const initialLocationError = {
    title: "",
    address: "",
    selectedFromDate: "",
    selectedToDate: "",
    days: ""
};

const initialLocation = {
    title: "",
    address: "",
    days: ""
};

const initialProfileError = {
    name: "",
    email: "",
    category: "",
    experience: "",
    websiteUrl: ""
};

const Dashboard = (props) => {

    const [selectedProfile, selectProfile] = useState({});
    const [selectedMenu, selectMenu] = useState("Gallery");
    const [menuOptions, updateMenuOptions] = useState([...initialMenuOptions]);
    const [addresses, updateAddresses] = useState([]);
    const [mode, updateMode] = useState("list");
    const [selectedFromDate, handleFromDateChange] = useState(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)));
    const [selectedToDate, handleToDateChange] = useState(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(30)).setSeconds(0)));
    const [daysArray, updateDaysArray] = useState([...days]);
    const [uploadedGalleryImage, updateGalleryImage] = useState(null);
    const [displayRemoveModal, openRemoveModal] = useState(false);
    const [removeType, updateRemoveType] = useState("");
    const [bankDetailsError, updateBankDetailsError] = useState({ ...initialBankDetailsError });
    const [locationDetails, modifyLocationDetails] = useState({ ...initialLocation });
    const [locationDetailsError, updateLocationDetailsError] = useState({ ...initialLocationError });
    const [selectedLocationIdToRemove, updateLocationId] = useState("");
    const [categoryList, updateCategoryList] = useState([]);
    const [profileError, updateProfileError] = useState({ ...initialProfileError });
    const [uploadedProfileImage, updateProfileImage] = useState("");
    const [selectedImageIdToRemove, updateImageId] = useState("");
    const [categoryDropdownOpened, toggleCategory] = useState(false);
    const [disablePage, toggleDisablePage] = useState(false);
    const [selectedCoordinates, updateCoordinate] = useState([]);
    const [openGalleryModal, toggleGalleryModal] = useState(false);
    const [currentImageIndex, updateCurrentImageIndex] = useState(-1);

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    const validateWebsite = (web) => {
        const re = /^((https|http):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/;
        return re.test(web);
    };

    const formatAMPM = (date) => {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    };

    const updateMenu = (item) => {
        const localMenuOptions = [...menuOptions];
        localMenuOptions.forEach(each => {
            each.selected = false;
            if (each.option === item.option) {
                each.selected = true;
            }
        });
        selectMenu(item.option);
        updateMenuOptions([...localMenuOptions]);
    };

    const getTime = (timeStr) => {
        var time = timeStr.slice(0, timeStr.length - 3)
        var amPm = timeStr.slice(timeStr.length - 2)
        var d = new Date()
        if (amPm === "AM" && time.split(":")[0] === "12") {
            return new Date(new Date(d.setHours(0)).setMinutes(time.split(":")[1]))
        }
        if (amPm === "PM" && time.split(":")[0] !== "12") {
            return new Date(new Date(d.setHours(Number(time.split(":")[0]) + 12)).setMinutes(time.split(":")[1]))
        }
        return new Date(new Date(d.setHours(time.split(":")[0])).setMinutes(time.split(":")[1]))
    };

    const getDuration = (times) => {
        times.sort((a, b) => { return a - b });
        const startTime = timeArr[times[0] - 1];
        const endTime = timeArr[times[times.length - 1]] ? timeArr[times[times.length - 1]] : timeArr[0];
        return `${startTime} - ${endTime}`;
    };

    const getStartTime = (times) => {
        times.sort((a, b) => { return a - b });
        return getTime(timeArr[times[0] - 1])
    };

    const getEndTime = (times) => {
        times.sort((a, b) => { return a - b });
        return getTime(timeArr[times[times.length - 1]] ? timeArr[times[times.length - 1]] : timeArr[0])
    };

    const getAllLocations = useCallback(async () => {
        toggleDisablePage(true);
        const locationsResult = await getLocationList();
        toggleDisablePage(false);
        if (locationsResult.data.errorCode) {
            toast.error(API_ERROR[locationsResult.data.errorCode]);
            return;
        }
        console.log("locationsResult ", locationsResult)
        const localAddresses = [];
        if (locationsResult.data.data && locationsResult.data.data.length) {
            let localSingleAddress = {};
            locationsResult.data.data.forEach(each => {
                localSingleAddress.title = each.title;
                localSingleAddress.address = each.address;
                localSingleAddress._id = each._id;
                localSingleAddress.selected = false;
                localSingleAddress.days = "";
                localSingleAddress.duration = getDuration(each.workingTimeSlots);
                localSingleAddress.daysOfWeek = each.daysOfWeek;
                localSingleAddress.workingTimeSlots = each.workingTimeSlots;
                localSingleAddress.location = each.location;

                if (each.daysOfWeek && each.daysOfWeek.length) {
                    each.daysOfWeek.forEach((item, index) => {
                        localSingleAddress.days += dayName[item - 1];
                        if (index !== each.daysOfWeek.length - 1) {
                            localSingleAddress.days += ", ";
                        }
                    });
                }
                localAddresses.push({ ...localSingleAddress });
                localSingleAddress = {};
            });
        }
        updateAddresses([...localAddresses]);
    }, []);

    const getAllCategories = async (categoryIds) => {
        toggleDisablePage(true);
        const categoriesResult = await getCategories();
        toggleDisablePage(false);
        if (categoriesResult.data.errorCode) {
            toast.error(API_ERROR[categoriesResult.data.errorCode]);
            return;
        }
        if (categoriesResult.data.data && categoriesResult.data.data.length) {
            categoriesResult.data.data.forEach(each => {
                if (categoryIds.includes(each._id)) {
                    each.selected = true;
                } else {
                    each.selected = false;
                }
            });
            updateCategoryList(categoriesResult.data.data);
        }
    };

    const getProfileDetails = async (resetType) => {
        toggleDisablePage(true);
        const profileData = await getProfile();
        toggleDisablePage(false);
        if (profileData.data.errorCode) {
            toast.error(API_ERROR[profileData.data.errorCode]);
            return;
        }
        if (profileData.data.data) {
            const localSelectedProfile = { ...selectedProfile };
            if ((!resetType || resetType === "bank") && profileData.data.data.bankingDetails) {
                localSelectedProfile.bankDetails = { ...profileData.data.data.bankingDetails };
            }
            if ((!resetType || resetType === "personalInfo") && profileData.data.data.personalInfo) {
                localSelectedProfile._id = profileData.data.data._id;
                localSelectedProfile.profilePicture = profileData.data.data.personalInfo.profilePicture;
                localSelectedProfile.name = profileData.data.data.personalInfo.name;
                localSelectedProfile.description = profileData.data.data.personalInfo.description;
                localSelectedProfile.email = profileData.data.data.personalInfo.email;
                localSelectedProfile.phoneNumber = '+91' + profileData.data.data.personalInfo.phone.number;
                localSelectedProfile.websiteUrl = profileData.data.data.personalInfo.website;
                if (profileData.data.data.personalInfo.experience && profileData.data.data.personalInfo.experience.year) {
                    localSelectedProfile.experience = profileData.data.data.personalInfo.experience;
                } else {
                    localSelectedProfile.experience = {
                        year: "",
                        month: ""
                    };
                }
                localSelectedProfile.categories = [];
                if (profileData.data.data.personalInfo.categories && profileData.data.data.personalInfo.categories.length) {
                    profileData.data.data.personalInfo.categories.forEach(each => {
                        localSelectedProfile.categories.push(each._id);
                    });
                }
            }
            if ((!resetType || resetType === "photos") && profileData.data.data.personalInfo) {
                localSelectedProfile.descriptionImages = profileData.data.data.photos;
            }
            console.log("localSelectedProfile ", localSelectedProfile)
            selectProfile({ ...localSelectedProfile });
            if (!resetType) {
                getAllCategories(localSelectedProfile.categories);
            }
        }
    };

    const getInitialProfileDetails = useCallback(async () => {
        toggleDisablePage(true);
        const profileData = await getProfile();
        toggleDisablePage(false);
        console.log("profileData ", profileData)
        if (profileData.data.errorCode) {
            toast.error(API_ERROR[profileData.data.errorCode]);
            return;
        }
        if (profileData.data.data) {
            const localSelectedProfile = {};
            if (profileData.data.data.bankingDetails) {
                localSelectedProfile.bankDetails = { ...profileData.data.data.bankingDetails };
            }
            if (profileData.data.data.personalInfo) {
                localSelectedProfile._id = profileData.data.data._id;
                localSelectedProfile.profilePicture = profileData.data.data.personalInfo.profilePicture;
                localSelectedProfile.name = profileData.data.data.personalInfo.name;
                localSelectedProfile.description = profileData.data.data.personalInfo.description;
                localSelectedProfile.email = profileData.data.data.personalInfo.email;
                localSelectedProfile.phoneNumber = '+91' + profileData.data.data.personalInfo.phone.number;
                localSelectedProfile.websiteUrl = profileData.data.data.personalInfo.website;
                if (profileData.data.data.personalInfo.experience && profileData.data.data.personalInfo.experience.year) {
                    localSelectedProfile.experience = profileData.data.data.personalInfo.experience;
                } else {
                    localSelectedProfile.experience = {
                        year: "",
                        month: ""
                    };
                }
                localSelectedProfile.categories = [];
                if (profileData.data.data.personalInfo.categories && profileData.data.data.personalInfo.categories.length) {
                    profileData.data.data.personalInfo.categories.forEach(each => {
                        localSelectedProfile.categories.push(each._id);
                    });
                }
            }
            if (profileData.data.data.personalInfo) {
                localSelectedProfile.descriptionImages = profileData.data.data.photos;
            }
            console.log("localSelectedProfile ", localSelectedProfile)
            selectProfile({ ...localSelectedProfile });
            getAllCategories(localSelectedProfile.categories);
        }
    }, []);

    const resetMenuOptions = () => {
        const localMenuOptions = [...initialMenuOptions];
        localMenuOptions.forEach(each => {
            each.selected = false;
            if (each.option === "Gallery") {
                each.selected = true;
            }
        });
        updateMenuOptions([...localMenuOptions]);
    };

    useEffect(() => {
        getInitialProfileDetails();
        getAllLocations();
        resetMenuOptions();
    }, [getInitialProfileDetails, getAllLocations]);

    useEffect(() => {
        window.onclick = (e) => {
            if (e.target.className.indexOf("custom-category-dropdown-select") <= -1 && e.target.className.indexOf("form-check") <= -1) {
                toggleCategory(false);
            }
        };
    });

    const openFileUploader = (imageId) => {
        if (document.getElementById(imageId)) {
            document.getElementById(imageId).click();
        }
    };

    const uploadGalleryImage = () => {
        if (document.getElementById("galleryImage")) {
            let imageFile = document.getElementById("galleryImage");
            if (imageFile.files[0]) {
                console.log("imageFile.files[0] ", imageFile.files[0]);
                if (imageFile.files[0].type.indexOf("image") > -1) {
                    updateGalleryImage(imageFile.files[0]);
                } else {
                    toast.error("Please upload image file");
                    return;
                }
            }
        }
    };

    const uploadProfileImage = async () => {
        if (document.getElementById("profileImage")) {
            let imageFile = document.getElementById("profileImage");
            if (imageFile.files[0]) {
                console.log("imageFile.files[0] ", imageFile.files[0]);
                if (imageFile.files[0].type.indexOf("image") > -1) {
                    updateProfileImage(URL.createObjectURL(imageFile.files[0]));
                    toggleDisablePage(true);
                    const result = await updateProfilePhoto(imageFile.files[0]);
                    toggleDisablePage(false);
                    if (result.data.errorCode) {
                        toast.error(API_ERROR[result.data.errorCode]);
                        return;
                    }
                    toast.success(API_SUCCESS.UPDATE_PROFILE_IMAGE);
                } else {
                    toast.error("Please upload image file");
                    return;
                }
            }
        }
    };

    const openRemove = (item, type) => {
        updateRemoveType(type);
        openRemoveModal(true);
        if (type === "Location") {
            updateLocationId(item._id);
        }
        if (type === "Image") {
            updateImageId(item._id);
        }
    };

    const removeUploadedImage = () => {
        updateGalleryImage(null);
        if (document.getElementById("galleryImage")) {
            document.getElementById("galleryImage").value = "";
        }
    };

    const updateBankDetails = (text, fieldName) => {
        const localProfile = { ...selectedProfile };
        const localBank = { ...localProfile.bankDetails };
        const localBankError = { ...bankDetailsError };
        localBank[fieldName] = text;
        localProfile.bankDetails = { ...localBank };
        localBankError[fieldName] = "";
        selectProfile({ ...localProfile });
        updateBankDetailsError({ ...localBankError });
    };

    const validateBankDetails = () => {
        const localProfile = { ...selectedProfile };
        const localBank = { ...localProfile.bankDetails };
        const localBankError = { ...bankDetailsError };
        let noErrorFlag = true;
        for (let item in localBank) {
            if (!localBank[item].trim().length) {
                localBankError[item] = REQUIRED_ERROR;
                noErrorFlag = false;
            } else {
                localBankError[item] = "";
            }
        }
        updateBankDetailsError({ ...localBankError });
        return noErrorFlag;
    };

    const saveBankDetails = async () => {
        if (validateBankDetails()) {
            toggleDisablePage(true);
            const result = await updateBank(selectedProfile.bankDetails);
            if (result.data.errorCode) {
                toast.error(API_ERROR[result.data.errorCode]);
                toggleDisablePage(false);
                return;
            }
            toast.success(API_SUCCESS.UPDATE_BANK);
            getProfileDetails("bank");
        }
    };

    const changePersonalInfo = (text, fieldName) => {
        const localProfile = { ...selectedProfile };
        const localProfileError = { ...profileError };
        if (fieldName !== "year" && fieldName !== "month") {
            localProfile[fieldName] = text;
            localProfileError[fieldName] = "";
        } else {
            localProfile.experience = { ...localProfile.experience };
            localProfile.experience[fieldName] = text;
            localProfileError.experience = "";
        }
        selectProfile({ ...localProfile });
        updateProfileError({ ...localProfileError });
    };

    const validateProfile = () => {
        const localProfileError = { ...profileError };
        let noErrorFlag = true;
        for (let item in localProfileError) {
            if (item === "name" || item === "email" || item === "websiteUrl") {
                if (item !== "websiteUrl" && !selectedProfile[item].trim().length) {
                    localProfileError[item] = REQUIRED_ERROR;
                    noErrorFlag = false;
                } else {
                    localProfileError[item] = "";
                }

                if (item === "email" && selectedProfile[item].trim().length) {
                    if (!validateEmail(selectedProfile[item])) {
                        localProfileError[item] = INVALID_EMAIL;
                        noErrorFlag = false;
                    } else {
                        localProfileError[item] = "";
                    }
                }

                if (item === "websiteUrl" && selectedProfile[item].trim().length) {
                    if (!validateWebsite(selectedProfile[item])) {
                        localProfileError[item] = INVALID_WEBSITE;
                        noErrorFlag = false;
                    } else {
                        localProfileError[item] = "";
                    }
                }
            }
            if (item === "experience") {
                console.log("selectedProfile.experience ", selectedProfile.experience);
                if (selectedProfile.experience.year === "" || selectedProfile.experience.month === "") {
                    localProfileError.experience = REQUIRED_ERROR;
                    noErrorFlag = false;
                } else {
                    localProfileError.experience = "";
                }

                if (selectedProfile.experience.month !== "") {
                    if (Number(selectedProfile.experience.month) > 11) {
                        localProfileError.experience = INVALID_MONTH;
                        noErrorFlag = false;
                    } else {
                        localProfileError.experience = "";
                    }
                }

            }
            if (item === "category") {
                let categoryError = true;
                if (categoryList.length) {
                    categoryList.forEach(each => {
                        if (each.selected) {
                            categoryError = false;
                        }
                    });
                }

                if (categoryError) {
                    localProfileError.category = CHOOSE_CATEGORY_ERROR;
                    noErrorFlag = false;
                } else {
                    localProfileError.category = "";
                }
            }
        }
        console.log("localProfileError ", localProfileError)
        updateProfileError({ ...localProfileError });
        return noErrorFlag;
    };

    const savePersonalInfo = async () => {
        console.log("profile--------- ", selectedProfile);
        if (validateProfile()) {
            const localSelectedCategories = [];
            if (categoryList.length) {
                categoryList.forEach(each => {
                    if (each.selected) {
                        localSelectedCategories.push(each._id);
                    }
                });
            }
            const requestBody = {
                name: selectedProfile.name,
                email: selectedProfile.email,
                categories: localSelectedCategories,
                experience: {
                    year: Number(selectedProfile.experience.year),
                    month: Number(selectedProfile.experience.month)
                }
            };
            if (selectedProfile.websiteUrl) {
                requestBody.website = selectedProfile.websiteUrl;
            }
            if (selectedProfile.description) {
                requestBody.description = selectedProfile.description;
            }
            toggleDisablePage(true);
            const result = await updateProfile(requestBody);
            if (result.data.errorCode) {
                toast.error(API_ERROR[result.data.errorCode]);
                toggleDisablePage(false);
                return;
            }
            toast.success(API_SUCCESS.UPDATE_PROFILE);
            getProfileDetails("personalInfo");
            props.updateProfile({ ...result.data.data });
        }
    };

    const updateLocationDetails = (text, fieldName) => {
        const localLocation = { ...locationDetails };
        const localLocationError = { ...locationDetailsError };
        localLocation[fieldName] = text;
        localLocationError[fieldName] = "";
        updateLocationDetailsError({ ...localLocationError });
        modifyLocationDetails({ ...localLocation });
    };

    const toggleDaySelection = (index) => {
        const localDays = [...daysArray];
        const localLocationError = { ...locationDetailsError };
        localDays[index].selected = !localDays[index].selected;
        localLocationError.days = "";
        updateDaysArray([...localDays]);
        updateLocationDetailsError({ ...localLocationError });
    };

    const updateDateError = () => {
        const localLocationError = { ...locationDetailsError };
        localLocationError.selectedFromDate = "";
        updateLocationDetailsError({ ...localLocationError });
    };

    const validateLocation = () => {
        const localLocationError = { ...locationDetailsError };
        console.log("locationDetails ", locationDetails)
        let daysError = true;
        let noError = true;
        if (!locationDetails.title.trim().length) {
            localLocationError.title = REQUIRED_ERROR;
            noError = false;
        } else {
            localLocationError.title = "";
        }

        if (!locationDetails.address.trim().length) {
            localLocationError.address = REQUIRED_ERROR;
            noError = false;
        } else {
            localLocationError.address = "";
        }

        if (new Date(selectedFromDate) > new Date(selectedToDate)) {
            localLocationError.selectedFromDate = INVALID_FROM_TO_DATE;
            noError = false;
        } else {
            localLocationError.selectedFromDate = "";
        }

        daysArray.forEach(each => {
            if (each.selected) {
                daysError = false;
            }
        });

        if (daysError) {
            localLocationError.days = CHOOSE_ERROR;
            noError = false;
        } else {
            localLocationError.days = "";
        }

        if (!selectedCoordinates.length) {
            localLocationError.address = INVALID_LOCATION;
            noError = false;
        } else {
            localLocationError.address = "";
        }

        updateLocationDetailsError({ ...localLocationError });
        return noError;
    };

    const openLocationList = () => {
        const localDays = [...daysArray];
        localDays.forEach(each => {
            each.selected = false;
        });
        updateDaysArray([...localDays]);
        updateMode("list");
        updateLocationDetailsError({ ...initialLocationError });
        getAllLocations();
        modifyLocationDetails({
            ...initialLocation
        });
        updateCoordinate([]);
        handleFromDateChange(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)));
        handleToDateChange(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(30)).setSeconds(0)));
    };

    const saveLocation = async (currentMode) => {
        if (!validateLocation()) {
            return;
        }
        let result;
        const fromIndex = (timeArr.indexOf(formatAMPM(selectedFromDate)) + 1);
        const toIndex = (timeArr.indexOf(formatAMPM(selectedToDate)));
        const localWorkingTimeSlots = [];
        const localDays = [];
        daysArray.forEach(each => {
            if (each.selected) {
                localDays.push(each.day);
            }
        });
        if (fromIndex === toIndex) {
            localWorkingTimeSlots.push(fromIndex);
        } else {
            for (let i = fromIndex; i <= toIndex; i++) {
                localWorkingTimeSlots.push(i);
            }
        }
        const requestBody = {
            title: locationDetails.title,
            address: locationDetails.address,
            workingTimeSlots: localWorkingTimeSlots,
            "location": {
                "coordinates": selectedCoordinates
            },
            daysOfWeek: localDays
        }
        toggleDisablePage(true);
        // return;
        if (currentMode === "add") {
            result = await addLocation(requestBody);
        } else {
            requestBody._id = locationDetails._id;
            result = await updateLocation(requestBody);
        }
        if (result.data.errorCode) {
            toast.error(API_ERROR[result.data.errorCode]);
            toggleDisablePage(false);
            return;
        }
        toast.success(currentMode === "add" ? API_SUCCESS.ADD_LOCATION : API_SUCCESS.UPDATE_LOCATION);

        openLocationList();

    };

    const chooseLocation = (locationData) => {
        console.log("locationdata ", locationData)
        updateMode("edit");
        const localLocationDetails = { ...locationDetails };
        const localDays = [...daysArray];
        localLocationDetails.title = locationData.title;
        localLocationDetails.address = locationData.address;
        localLocationDetails._id = locationData._id;
        handleFromDateChange(getStartTime(locationData.workingTimeSlots));
        handleToDateChange(getEndTime(locationData.workingTimeSlots));
        updateCoordinate(locationData.location.coordinates);

        if (locationData.daysOfWeek && locationData.daysOfWeek.length) {
            locationData.daysOfWeek.forEach((each, index) => {
                localDays.forEach(each1 => {
                    if (each === each1.day) {
                        each1.selected = true;
                    }
                });
            });
        }
        updateDaysArray([...localDays]);
        modifyLocationDetails({ ...localLocationDetails });
    };

    const removeData = async () => {
        if (removeType === "Location") {
            toggleDisablePage(true);
            const result = await removeLocation(selectedLocationIdToRemove);
            if (result.data.errorCode) {
                toast.error(API_ERROR[result.data.errorCode]);
                toggleDisablePage(false);
                return;
            }
            toast.success(API_SUCCESS.REMOVE_LOCATION);
            updateLocationId("");
            openLocationList();
            openRemoveModal(false);
        }
        if (removeType === "Image") {
            toggleDisablePage(true);
            const result = await removeImage(selectedImageIdToRemove);
            if (result.data.errorCode) {
                toast.error(API_ERROR[result.data.errorCode]);
                toggleDisablePage(false);
                return;
            }
            toast.success(API_SUCCESS.REMOVE_IMAGE);
            updateImageId("");
            getProfileDetails("photos");
            openRemoveModal(false);
        }
    };

    const toggleCategorySelection = (categoryValue, index) => {
        const localCateories = [...categoryList];
        const localProfileError = { ...profileError };
        const localProfileDetails = { ...selectedProfile };

        localCateories[index].selected = !localCateories[index].selected;
        localProfileError.category = "";

        if (localProfileDetails.categories.includes(categoryValue)) {
            localProfileDetails.categories.splice(categoryValue, 1);
        } else {
            localProfileDetails.categories.push(categoryValue);
        }

        selectProfile({ ...localProfileDetails });

        console.log("selectedProfile ", selectedProfile, categoryValue)
        updateCategoryList([...localCateories]);
        updateProfileError({ ...localProfileError });
    };

    const saveGalleryImage = async () => {
        toggleDisablePage(true);
        const result = await addPhoto(uploadedGalleryImage);
        if (result.data.errorCode) {
            toast.error(API_ERROR[result.data.errorCode]);
            toggleDisablePage(false);
            return;
        }
        toast.success(API_SUCCESS.ADD_IMAGE);
        removeUploadedImage();
        getProfileDetails("photos");
    };

    const initAutocomplete = () => {
        updateCoordinate([]);
        if (window.google) {
            const map = new window.google.maps.Map(document.getElementById("map"), {
                center: { lat: -33.8688, lng: 151.2195 },
                zoom: 13,
                mapTypeId: "roadmap",
            });
            // Create the search box and link it to the UI element.
            const input = document.getElementById("location-search-input");
            const searchBox = new window.google.maps.places.SearchBox(input);
            map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);
            // Bias the SearchBox results towards current map's viewport.
            map.addListener("bounds_changed", () => {
                searchBox.setBounds(map.getBounds());
            });
            // Listen for the event fired when the user selects a prediction and retrieve
            // more details for that place.
            searchBox.addListener("places_changed", () => {
                const places = searchBox.getPlaces();

                if (places.length === 0) {
                    return;
                }

                // For each place, get the icon, name and location.
                const bounds = new window.google.maps.LatLngBounds();
                places.forEach((place) => {
                    if (!place.geometry || !place.geometry.location) {
                        console.log("Returned place contains no geometry");
                        return;
                    }

                    if (place.geometry.viewport) {
                        // Only geocodes have viewport.
                        bounds.union(place.geometry.viewport);
                    } else {
                        bounds.extend(place.geometry.location);
                    }
                    updateCoordinate([place.geometry.location.lng(), place.geometry.location.lat()]);
                    updateLocationDetails(place.formatted_address, 'address');
                    // console.log("places ",place)
                });
                map.fitBounds(bounds);
            });
        }

    };

    const chooseGalleryImage = (index) => {
        updateCurrentImageIndex(index);
        toggleGalleryModal(true);
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className={`row dashboard ${disablePage ? "pointer-none" : ""}`} style={{ margin: 0 }}>
                <RemoveModal
                    displayRemoveModal={displayRemoveModal}
                    openRemoveModal={openRemoveModal}
                    removeType={removeType}
                    removeData={removeData} />
                <div className="col-12 categories-heading">
                    <h4 className={`mb-0`}>My Profile</h4>
                </div>
                <div className="col-md-4">
                    <div className="row profile-details-wrapper" style={{ margin: 0 }}>
                        <div className="col-12 p-0 mb-3 add-edit-service-button-wrapper">
                            <button type="button" className="btn btn-primary btn-sm save-button" onClick={savePersonalInfo}>Save</button>
                            <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => { updateProfileError({ ...initialProfileError }); getProfileDetails('personalInfo') }}>Reset</button>
                        </div>
                        {!uploadedProfileImage ?
                            <div className="col-12 p-0 profile-image-wrapper mb-3">
                                {selectedProfile.profilePicture ?
                                    <div className="profile-image" style={{ backgroundImage: "url(" + selectedProfile.profilePicture + ")" }}>
                                        <div className="file-uploader">
                                            <input type="file" id="profileImage" accept="image/*" hidden onChange={uploadProfileImage} />
                                            <i className="fa fa-camera" aria-hidden="true" onClick={() => openFileUploader("profileImage")}></i>
                                        </div>
                                    </div> :
                                    <div className="profile-image" style={{ backgroundImage: "url(" + require("../../../images/imgAvtr.png") + ")" }}>
                                        <div className="file-uploader">
                                            <input type="file" id="profileImage" accept="image/*" hidden onChange={uploadProfileImage} />
                                            <i className="fa fa-camera" aria-hidden="true" onClick={() => openFileUploader("profileImage")}></i>
                                        </div>
                                    </div>
                                }

                            </div>
                            :
                            <div className="col-12 p-0 profile-image-wrapper mb-3">
                                <div className="profile-image" style={{ backgroundImage: "url(" + uploadedProfileImage + ")" }}>
                                    <div className="file-uploader">
                                        <input type="file" id="profileImage" accept="image/*" hidden onChange={uploadProfileImage} />
                                        <i className="fa fa-camera" aria-hidden="true" onClick={() => openFileUploader("profileImage")}></i>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="col-12 p-0 mb-3">
                            <label for="exampleFormControlInput1" className="form-label">Name</label>
                            <input type="text" className={`form-control ${profileError.name ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter Name" value={selectedProfile.name} onChange={e => changePersonalInfo(e.target.value, 'name')} />
                            {profileError.name ?
                                <div className="input-error">{profileError.name}</div> : null}
                        </div>
                        <div className="col-12 p-0 mb-3">
                            <label for="exampleFormControlInput1" className="form-label">Phone Number</label>
                            <input type="text" className={`form-control`} value={selectedProfile.phoneNumber} disabled />
                        </div>
                        {categoryList.length ?
                            <div className="col-12 p-0 mb-3 custom-category-dropdown-wrapper">
                                <label for="exampleFormControlInput1" className="form-label">Professional Category</label>
                                <div className="col-12 custom-category-dropdown-select" onClick={() => toggleCategory(!categoryDropdownOpened)}>
                                    {selectedProfile.categories && selectedProfile.categories.length ? `${selectedProfile.categories.length} Selected` : 'Select'}
                                    <i className="fa fa-caret-down" aria-hidden="true"></i>
                                </div>
                                {categoryDropdownOpened ?
                                    <div className="row" style={{ margin: 0 }}>
                                        {categoryList.length ? categoryList.map((dayValue, index) => {
                                            return <div className="col-12" key={dayValue._id}>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id={dayValue._id} checked={dayValue.selected} onChange={() => toggleCategorySelection(dayValue._id, index)} />
                                                    <label className="form-check-label" for={dayValue._id}>
                                                        {dayValue.title}
                                                    </label>
                                                </div>
                                            </div>
                                        }) : null}
                                    </div> : null}
                                {profileError.category ?
                                    <div className="input-error">{profileError.category}</div> : null}
                            </div> : null}

                        <div className="col-12 p-0 mb-3">
                            <label for="exampleFormControlInput1" className="form-label">Total Exp</label>
                            <div className="row m-0">
                                <div className="col-6 pl-0">
                                    <input type="text" className={`form-control ${profileError.experience && !selectedProfile.experience.year ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="in year" value={selectedProfile.experience ? selectedProfile.experience.year : ""} onChange={e => { changePersonalInfo(e.target.value.replace(/\D/, ''), 'year') }} />
                                </div>
                                <div className="col-6 pr-0">
                                    <input type="text" className={`form-control ${profileError.experience && (!selectedProfile.experience.month || profileError.experience === INVALID_MONTH) ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="in month" value={selectedProfile.experience ? selectedProfile.experience.month : ""} onChange={e => { changePersonalInfo(e.target.value.replace(/\D/, ''), 'month') }} />
                                </div>
                            </div>
                            {profileError.experience ?
                                <div className="input-error">{profileError.experience}</div> : null}
                        </div>
                        <div className="col-12 p-0 mb-3">
                            <label for="exampleFormControlInput1" className="form-label">Email</label>
                            <input type="text" className={`form-control ${profileError.email ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter Email" value={selectedProfile.email} onChange={e => changePersonalInfo(e.target.value, 'email')} />
                            {profileError.email ?
                                <div className="input-error">{profileError.email}</div> : null}
                        </div>
                        <div className="col-12 p-0 mb-3">
                            <label for="exampleFormControlInput1" className="form-label">Website</label>
                            {selectedProfile.websiteUrl ?
                                <img src={require('../../../images/globe.svg')} style={{ marginLeft: "5px", cursor: "pointer" }} alt="website" onClick={() => window.open(selectedProfile.websiteUrl)} /> : null}
                            <input type="text" className={`form-control ${profileError.websiteUrl ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter Website" value={selectedProfile.websiteUrl} onChange={e => changePersonalInfo(e.target.value, 'websiteUrl')} />
                            <div className="example-text">Website url e.g.: https://your-website.com</div>
                            {profileError.websiteUrl ?
                                <div className="input-error">{profileError.websiteUrl}</div> : null}
                        </div>
                        <div className="col-12 p-0">
                            <label for="exampleFormControlTextarea1" className="form-label">Description</label>
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" value={selectedProfile.description} onChange={e => changePersonalInfo(e.target.value, 'description')}></textarea>
                        </div>
                    </div>
                </div>
                <div className="col-md-8 margin-top-less-768">
                    <div className="row profile-details-wrapper" style={{ margin: 0 }}>
                        <div className="col-12 p-0">
                            <ul className="nav nav-tabs profile-menu-options">
                                {menuOptions.map((menu, index) => {
                                    return <li className="nav-item" key={index} onClick={() => updateMenu(menu)}>
                                        <span className={`nav-link ${menu.selected ? 'active' : ''}`}>{menu.option}</span>
                                    </li>
                                })}
                            </ul>
                        </div>
                        <div className="col-12 p-0">
                            {selectedMenu === "Gallery" ?
                                <div className="col-12 p-0">
                                    <div className="row" style={{ margin: "0" }}>
                                        <div className="col-12 p-0">
                                            <div className="row" style={{ margin: 0 }}>
                                                <div className={`col-sm-6 col-xs-12 pl-0 pt-2 ${!uploadedGalleryImage ? 'mb-3' : ''}`}>
                                                    <h5 className={`mb-0`}>Gallery</h5>
                                                </div>
                                                {uploadedGalleryImage ?
                                                    <div className="col-sm-6 col-xs-12 pr-0 text-right mb-3">
                                                        <button type="button" className="btn btn-outline-primary btn-sm mr-2" onClick={removeUploadedImage}>Cancel</button>
                                                        <button type="button" className="btn btn-primary btn-sm" onClick={saveGalleryImage}>Save</button>
                                                    </div> : null}
                                            </div>
                                        </div>
                                        {selectedProfile.descriptionImages && selectedProfile.descriptionImages.length ?
                                            selectedProfile.descriptionImages.map((image, index) => {
                                                return <div className={`about-image-wrapper pl-0`} key={index} onClick={() => chooseGalleryImage(index)}>
                                                    <div className="profile-image description-image" style={{ backgroundImage: "url(" + image.url + ")" }} />
                                                    <img src={require("../../../images/remove.svg")} onClick={e => { e.stopPropagation(); openRemove(image, "Image") }} alt={`remove gallery`} />
                                                </div>
                                            })
                                            : null}
                                        {!uploadedGalleryImage ?
                                            <div className={`about-image-wrapper pl-0`}>
                                                <input type="file" accept="image/*" id="galleryImage" hidden onChange={uploadGalleryImage} />
                                                <div className="profile-image description-image" style={{ backgroundColor: "#eee", backgroundImage: "url(" + require("../../../images/plus.svg") + ")" }} onClick={() => openFileUploader("galleryImage")} />
                                            </div> :
                                            <div className={`about-image-wrapper pl-0`}>
                                                <input type="file" accept="image/*" id="galleryImage" hidden onChange={uploadGalleryImage} />
                                                <div className="profile-image description-image" style={{ backgroundColor: "#eee", backgroundImage: "url(" + URL.createObjectURL(uploadedGalleryImage) + ")" }} />
                                            </div>
                                        }
                                    </div>
                                </div>
                                : null}
                            {selectedMenu === "Bank Details" ?
                                <div className="col-12 p-0">
                                    <div className="row" style={{ margin: 0 }}>
                                        <div className="col-12 p-0">
                                            <div className="row" style={{ margin: 0 }}>
                                                <div className="col-sm-6 col-xs-12 pl-0 pt-2">
                                                    <h5 className={`mb-0`}>Bank Details</h5>
                                                </div>
                                                <div className="col-sm-6 col-xs-12 pr-0 text-right mb-3">
                                                    <button type="button" className="btn btn-outline-primary btn-sm mr-2" onClick={() => { updateBankDetailsError({ ...initialBankDetailsError }); getProfileDetails("bank") }}>Cancel</button>
                                                    <button type="button" className="btn btn-primary btn-sm" onClick={saveBankDetails}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-xs-12 left-padding mb-3">
                                            <label for="exampleFormControlInput1" className="form-label">Bank Name</label>
                                            <input type="text" className={`form-control ${bankDetailsError.bankName ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter Bank Name" value={selectedProfile.bankDetails.bankName} onChange={e => updateBankDetails(e.target.value, 'bankName')} />
                                            {bankDetailsError.bankName ?
                                                <div className="input-error">{bankDetailsError.bankName}</div> : null}
                                        </div>
                                        <div className="col-md-6 col-xs-12 right-padding mb-3">
                                            <label for="exampleFormControlInput1" className="form-label">Account Holder Name</label>
                                            <input type="text" className={`form-control ${bankDetailsError.accountHolderName ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter Account Holder Name" value={selectedProfile.bankDetails.accountHolderName} onChange={e => updateBankDetails(e.target.value, 'accountHolderName')} />
                                            {bankDetailsError.accountHolderName ?
                                                <div className="input-error">{bankDetailsError.accountHolderName}</div> : null}
                                        </div>

                                        <div className="col-md-6 col-xs-12 left-padding mb-3">
                                            <label for="exampleFormControlInput1" className="form-label">Account Number</label>
                                            <input type="text" className={`form-control ${bankDetailsError.accountNumber ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter Account Number" value={selectedProfile.bankDetails.accountNumber} onChange={e => updateBankDetails(e.target.value.replace(/\D/, ''), 'accountNumber')} />
                                            {bankDetailsError.accountNumber ?
                                                <div className="input-error">{bankDetailsError.accountNumber}</div> : null}
                                        </div>
                                        <div className="col-md-6 col-xs-12 right-padding mb-3">
                                            <label for="exampleFormControlInput1" className="form-label">IFSC Code</label>
                                            <input type="text" className={`form-control ${bankDetailsError.ifscCode ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter IFSC Code" value={selectedProfile.bankDetails.ifscCode} onChange={e => updateBankDetails(e.target.value, 'ifscCode')} />
                                            {bankDetailsError.ifscCode ?
                                                <div className="input-error">{bankDetailsError.ifscCode}</div> : null}
                                        </div>
                                        <div className="col-md-6 col-xs-12 left-padding mb-3">
                                            <label for="exampleFormControlInput1" className="form-label">Branch</label>
                                            <input type="text" className={`form-control ${bankDetailsError.branchName ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter Branch Name" value={selectedProfile.bankDetails.branchName} onChange={e => updateBankDetails(e.target.value, 'branchName')} />
                                            {bankDetailsError.branchName ?
                                                <div className="input-error">{bankDetailsError.branchName}</div> : null}
                                        </div>
                                        <div className="col-md-6 col-xs-12 right-padding mb-3">
                                            <label for="exampleFormControlInput1" className="form-label">GST Code</label>
                                            <input type="text" className={`form-control ${bankDetailsError.gstNumber ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter GST Code" value={selectedProfile.bankDetails.gstNumber} onChange={e => updateBankDetails(e.target.value, 'gstNumber')} />
                                            {bankDetailsError.gstNumber ?
                                                <div className="input-error">{bankDetailsError.gstNumber}</div> : null}
                                        </div>
                                    </div>
                                </div>
                                : null}
                            {selectedMenu === "Address Book" ?
                                <div className="col-12 p-0">
                                    {mode === "list" ?
                                        <div className="row" style={{ margin: 0 }}>
                                            <div className="col-12 p-0">
                                                <div className="row" style={{ margin: 0 }}>
                                                    <div className="col-sm-8 col-xs-12 pl-0 pt-2">
                                                        <h5 className={`mb-0`}>Location List</h5>
                                                    </div>
                                                    <div className="col-sm-4 col-xs-12 pr-0 text-right mb-3">
                                                        <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => updateMode("add")}>Add Location</button>
                                                    </div>
                                                </div>
                                            </div>
                                            {addresses.length ?
                                                addresses.map((professional, index) => {
                                                    return <div className={`col-sm-6 col-xs-12 professional-wrapper
                                        ${index % 2 === 0 ? 'problem-more-768-pr-15-for-2' : ''}
                                        ${index !== addresses.length - 1 && index !== addresses.length - 2 && index !== addresses.length - 3 ? 'problem-more-768-pb-15' : ''}
                                        ${index !== addresses.length - 1 && index !== addresses.length - 2 ? 'problem-more-768-pb-15-for-2' : ''}
                                        ${index !== addresses.length - 1 ? 'problem-more-768-pb-15-for-1' : ''}
                                        `} key={index} onClick={() => chooseLocation(professional)}>
                                                        <div className="row service-row" style={{ margin: 0 }}>
                                                            <div className="col-12 p-0">
                                                                <div className="row" style={{ margin: 0 }}>
                                                                    <div className="professional-name col-12 p-0">{professional.title}
                                                                    </div>
                                                                    <div className="professional-location service-name col-12 p-0">{professional.address}</div>
                                                                    <div className="col-5 pl-0">
                                                                        <div className="service-cost">{professional.days}</div>
                                                                    </div>
                                                                    <div className="col-7 pr-0 text-right">
                                                                        <div className="service-hour">{professional.duration}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <img src={require("../../../images/remove.svg")} onClick={(e) => { e.stopPropagation(); openRemove(professional, "Location") }} alt="remove location" />
                                                        </div>
                                                    </div>
                                                })
                                                : <div className="text-center well col-12 mb-3 color-black">
                                                    No Locations Available!
                                </div>}
                                        </div> : null}
                                    {mode === "add" || mode === "edit" ?
                                        <div className="row" style={{ margin: 0 }}>
                                            <div className="col-12 p-0">
                                                <div className="row" style={{ margin: 0 }}>
                                                    <div className="col-sm-6 col-xs-12 pl-0 pt-2">
                                                        <h5 className={`mb-0`}>{mode === "add" ? "Add" : "Edit"} Location</h5>
                                                    </div>
                                                    <div className="col-sm-6 col-xs-12 pr-0 text-right mb-3">
                                                        <button type="button" className="btn btn-outline-primary btn-sm mr-2" onClick={() => { updateLocationDetailsError({ ...initialLocationError }); openLocationList() }}>Cancel</button>
                                                        <button type="button" className="btn btn-primary btn-sm" onClick={() => saveLocation(mode)}>Save</button>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ margin: 0 }}>
                                                    <div className="col-md-6 col-xs-12 left-padding mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">Location Nickname</label>
                                                        <input type="text" className={`form-control ${locationDetailsError.title ? 'error-border' : ''}`} id="exampleFormControlInput1" placeholder="Enter Location Nickname" value={locationDetails.title} onChange={e => updateLocationDetails(e.target.value, "title")} />
                                                        {locationDetailsError.title ?
                                                            <div className="input-error">{locationDetailsError.title}</div> : null}
                                                    </div>
                                                    <div className="col-md-6 col-xs-12 right-padding mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">Complete Address with Landmark</label>
                                                        <input type="text" className={`form-control ${locationDetailsError.address ? 'error-border' : ''}`} id="location-search-input" placeholder="Enter Complete Address" value={locationDetails.address} onChange={e => { initAutocomplete(); updateLocationDetails(e.target.value, "address") }} />
                                                        {locationDetailsError.address ?
                                                            <div className="input-error">{locationDetailsError.address}</div> : null}
                                                    </div>
                                                    <div className="col-md-6 col-xs-12 left-padding mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">Time From</label><br />
                                                        <TimePicker className={`${locationDetailsError.selectedFromDate ? 'timepicker-error-border' : ''}`} value={selectedFromDate} onChange={handleFromDateChange} onClose={updateDateError} minutesStep={30} />
                                                        {locationDetailsError.selectedFromDate ?
                                                            <div className="input-error">{locationDetailsError.selectedFromDate}</div> : null}
                                                    </div>
                                                    <div className="col-md-6 col-xs-12 right-padding mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">Time To</label><br />
                                                        <TimePicker className={`${locationDetailsError.selectedFromDate ? 'timepicker-error-border' : ''}`} value={selectedToDate} onChange={handleToDateChange} minutesStep={30} onClose={updateDateError} />
                                                    </div>
                                                    <div className="col-12 p-0 mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">Choose Day(s)</label>
                                                        <div className="row" style={{ maargin: 0 }}>
                                                            {daysArray.length ? daysArray.map((dayValue, index) => {
                                                                return <div className="col-md-3 col-6" key={index}>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={dayValue.selected} onChange={() => toggleDaySelection(index)} />
                                                                        <label className="form-check-label" for="flexCheckDefault">
                                                                            {dayValue.text}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            }) : null}
                                                        </div>
                                                        {locationDetailsError.days ?
                                                            <div className="input-error">{locationDetailsError.days}</div> : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                </div>
                                : null}
                        </div>
                    </div>
                    <div className="row ml-0 mr-0 mt-3 profile-details-wrapper">
                        <div className="col-sm-10 col-xs-12 pl-0" style={{color: "#000",
                            fontWeight: 400}}>
                            Check how it is going to appear in customer application. Clicking on preview button will open customer app in a new tab.
                        </div>
                        <div className="col-sm-2 col-xs-12 text-right pr-0">
                            <button className="btn btn-outline-primary" onClick={() => window.open(`https://student.parentcraftindia.com/professional-details/${selectedProfile._id}`)}>Preview</button>
                        </div>
                    </div>
                </div>
            </div>
            {selectedProfile.descriptionImages && openGalleryModal ?
                <GalleryModal openGalleryModal={openGalleryModal} galleryImages={selectedProfile.descriptionImages} currentImageIndex={currentImageIndex} toggleGalleryModal={toggleGalleryModal} updateCurrentImageIndex={updateCurrentImageIndex} /> : null}
        </MuiPickersUtilsProvider>
    )
};

const mapStateToProps = state => {
    return { data: state };
};

export default injectIntl(
    connect(
        mapStateToProps,
        auth.actions
    )(Dashboard)
);
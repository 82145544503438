import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../../store/ducks/auth.duck";
import { confirmLoginOtp, requestLoginOtp } from "../../crud/auth.crud";
import "./auth.css";
import { REQUIRED_ERROR, VALID_PHONE_ERROR, API_ERROR } from "../../config/error-message";
import { API_SUCCESS } from "../../config/success-message";

function Login(props) {
  const [displayLoginOtp, toggleLogin] = useState(false);
  const [phoneNumber, updatePhoneNumber] = useState("");
  const [phoneNumberError, updatePhoneError] = useState("");
  const [isLoginTermChecked, changeCheckbox] = useState(false);
  const [otpDetails, updateOtpDetails] = useState({});
  const [otpArray, updateOtpArray] = useState(['', '', '', '']);
  const [disablePage, toggleDisablePage] = useState(false);

  const validatePhone = () => {
    if (!phoneNumber.trim().length) {
      updatePhoneError(REQUIRED_ERROR);
      return false;
    }
    if (phoneNumber.length < 10) {
      updatePhoneError(VALID_PHONE_ERROR);
      return false;
    }
    updatePhoneError("");
    return true;
  };

  const getOtp = async () => {
    if (validatePhone()) {
      toggleDisablePage(true);
      const result = await requestLoginOtp({
        "phoneNumber": phoneNumber,
        "countryCode": "IN",
        "dialCode": "+91"
      });
      toggleDisablePage(false);
      if (result.data.errorCode) {
        toast.error(API_ERROR[result.data.errorCode]);
        return;
      }
      console.log("result ", result);
      if (result.data.data && result.data.data.otp) {
        updateOtpDetails({ ...result.data.data });
        toggleLogin(true);
        toast.info(`Your otp is: ${result.data.data.otp}`);
      }
    }
  };

  const modifyOtp = (otpText, index) => {
    const localOtpArray = [...otpArray];
    const regex = /^-?\d+\.?\d*$/;
    localOtpArray[index] = otpText;
    if (regex.test(otpText) && document.getElementById(`otp${index + 1}`)) {
      document.getElementById(`otp${index + 1}`).focus();
    }
    updateOtpArray([...localOtpArray]);
  };

  const confirmOtp = async () => {
    toggleDisablePage(true);
    const result = await confirmLoginOtp({
      vendorAuthId: otpDetails.vendorAuthId,
      otp: otpArray.join("")
    });
    toggleDisablePage(false);
    if (result.data.errorCode) {
      toast.error(API_ERROR[result.data.errorCode]);
      return;
    }
    toast.success(API_SUCCESS.LOGIN_SUCCESS);
    console.log("result1 ", result);
    props.login(result.data.data);
  };

  const resendOtp = () => {
    getOtp();
    updateOtpArray(['', '', '', '']);
  };

  const backToLogin = () => {
    updatePhoneNumber("");
    updateOtpDetails({});
    updateOtpArray(['', '', '', '']);
    toggleLogin(false);
  };

  useEffect(() => {
    console.log("props ", props)
  }, [props])

  return (
    <>
      <div className={`kt-login__body ${disablePage ? "pointer-none" : ""}`}>
        <div className="kt-login__form">
          <div className={` login-modal `} id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="row" style={{ margin: 0 }} role="document">
              <div className="col-12 login-logo-wrapper">
                <img src={require("../../../images/logo-blue.png")} style={{ width: "100px", height: "100px" }} alt="login logo" />
              </div>
              <div className="col-12 text-center">
                <div className="welcome-text">{!displayLoginOtp ? 'Parentcraft Professionals' : 'Verification'}</div>
                <div className="sign-in-text">{!displayLoginOtp ? 'Sign in to Continue' : 'Please enter the OTP received via SMS'}</div>
              </div>
              {!displayLoginOtp ?
                <div className="col-12 login-phone-input mb-3">
                  <div className={`input-group ${phoneNumberError ? "error-border" : ""}`}>
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">+91</span>
                    </div>
                    <input type="text" className="form-control" placeholder="Phone Number" aria-label="Phone Number" aria-describedby="basic-addon1" value={phoneNumber} onChange={e => { updatePhoneError(""); updatePhoneNumber(e.target.value.replace(/\D/, '')) }} maxLength={10} />
                  </div>
                  {phoneNumberError ?
                    <div className="input-error">{phoneNumberError}</div> : null}
                </div> :
                <div className="col-12 login-phone-input">

                  <div className="row form-group login-otp-input-wrapper" style={{ margin: 0 }}>
                    {otpArray.map((item, index) => {
                      return <div className="col-3 pl-0" key={index}>
                        <input type="text" className="form-control" id={`otp${index}`} value={item} onChange={e => modifyOtp(e.target.value.replace(/\D/, ''), index)} maxLength={1} />
                      </div>
                    })}
                  </div>
                </div>}
              {!displayLoginOtp ?
                <div className="col-12 login-button">
                  <button type="button" className="btn btn-primary" onClick={getOtp} disabled={!isLoginTermChecked || !phoneNumber}>Next</button>
                </div> :
                <div className="col-12 login-button">
                  <button type="button" className="btn btn-primary" onClick={confirmOtp} disabled={!otpArray[0].length || !otpArray[1].length || !otpArray[2].length || !otpArray[3].length}>Confirm OTP</button>
                  <button type="button" className="btn btn-link cancel-otp-page" onClick={backToLogin}>Back</button>
                  <button type="button" className="btn btn-link" onClick={resendOtp}>Resend OTP</button>
                </div>
              }
              {!displayLoginOtp ?
                <div className="col-12 accept-checkbox-wrapper">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" checked={isLoginTermChecked} onChange={() => changeCheckbox(!isLoginTermChecked)} />
                    <label className="form-check-label">
                      By Signing in, you accept our <span onClick={() => window.open("https://www.parentcraftindia.com/privacy-policies")}>Privacy policy</span> and <span onClick={() => window.open("https://www.parentcraftindia.com/termsandconditions")}>Terms of use</span>
                    </label>
                  </div>
                </div> : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return { data: state };
};

export default injectIntl(
  connect(
    mapStateToProps,
    auth.actions
  )(Login)
);

import React, { useEffect, useState, useCallback } from 'react';
import Calendar from 'react-calendar';
import "./service.css";
import 'react-calendar/dist/Calendar.css';
import { getBookings, completeBooking, cancelBook, blockSlotAPI, unblockSlotAPI } from "../../crud/booking.crud";
import { toast } from 'react-toastify';
import {
    API_ERROR,
    CALL_TIME_PASSED,
    CANCEL_TIME_PASSED,
    COMPLETE_TIME_PASSED,
    INVALID_FROM_TO_DATE
} from "../../config/error-message";
import { API_SUCCESS } from "../../config/success-message";
import {
    timeArrConst,
    BookingConfig
} from "../../config/constants";
import DateFnsUtils from '@date-io/date-fns';
import {
    TimePicker,
    MuiPickersUtilsProvider,
  } from '@material-ui/pickers';
import CancelModal from "../../router/cancel-modal";
import UnblockModal from "../../router/unblock-modal";
import HolidayModal from "./holiday-modal";

const defaultImage = require('../../../images/imgAvtr.png');
const timeArr = [...timeArrConst];
const initialLocationError = {
    selectedFromDate: ""
};

const Scheduler = (props) => {
    const [selectedDate, onSelectedDateChange] = useState(new Date());
    const [bookings, updateBookings] = useState([]);
    const [otpArray, updateOtpArray] = useState(['', '', '', '']);
    const [disablePage, toggleDisablePage] = useState(false);
    const [selectedFromDate, handleFromDateChange] = useState(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)));
    const [selectedToDate, handleToDateChange] = useState(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(30)).setSeconds(0)));
    const [locationDetailsError, updateLocationDetailsError] = useState({...initialLocationError});
    const [displaySlotModal, toggleSlotModal] = useState(false);
    const [loadingBookingList, toggleLoadingBookingList] = useState(true);
    const [displayCancelModal, toggleCancelModal] = useState(false);
    const [cancelableObject, updateCancelableObject] = useState({});
    const [displayHolidayModal, toggleHolidayModal] = useState(false);
    const [displayUnblockModal, toggleUnblockModal] = useState(false);
    const [unblockableObject, updateUnblockableObject] = useState({});

    const getTimestamp = (date) => {
        return new Date(new Date(new Date(new Date(new Date(date).setHours(0)).setMinutes(0)).setSeconds(0)).setMilliseconds(0)).getTime();
    };

    const getISOString = (date) => {
        return new Date(new Date(new Date(new Date(new Date(date).setHours(5)).setMinutes(30)).setSeconds(0)).setMilliseconds(0)).toISOString();
    };

    const getFullDateFormat = (date) => {
        const day = date.getDate().toString().length === 1 ? "0" + date.getDate() : date.getDate();
        const month = (date.getMonth() + 1).toString().length === 1 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const getStartTime = (times) => {
        times.sort((a, b) => { return a - b });
        return timeArr[times[0] - 1];
    };

    const getEndTime = (times) => {
        times.sort((a, b) => { return a - b });
        return timeArr[times[times.length - 1]] ? timeArr[times[times.length - 1]] : timeArr[0];
    };

    const checkCurrentDay = (date) => {
        const today = new Date(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)).setMilliseconds(0)).getTime();
        const day = new Date(new Date(new Date(new Date(new Date(date).setHours(0)).setMinutes(0)).setSeconds(0)).setMilliseconds(0)).getTime();
        console.log("today ", today, day)
        return today === day;
    };

    const getTime = (timeStr, date) => {
        const time = timeStr.slice(0, timeStr.length - 3)
        const amPm = timeStr.slice(timeStr.length - 2)
        // console.log("time ", amPm, time)
        let d = new Date();
        if (date) {
            d = new Date(date);
        }
        if (amPm === "AM" && time.split(":")[0] === "12") {
            return new Date(new Date(new Date(new Date(d.setHours(0)).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
        }
        if (amPm === "PM" && time.split(":")[0] !== "12") {
            return new Date(new Date(new Date(new Date(d.setHours(Number(time.split(":")[0]) + 12)).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
        }
        return new Date(new Date(new Date(new Date(d.setHours(time.split(":")[0])).setMinutes(time.split(":")[1])).setSeconds(0)).setMilliseconds(0)).getTime();
    };

    const formatAMPM = (date) => {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    };

    const checkJoiningCallTime = useCallback((localBookingData) => {
        console.log("in here ", localBookingData)

        if (checkCurrentDay(localBookingData.slotDate)) {
            const today = new Date().getTime();
            const startTime = getTime(timeArr[localBookingData.slots[0] - 1]);
            const endTime = getTime(timeArr[localBookingData.slots[localBookingData.slots.length - 1]]);
            console.log("start-------> ", startTime, endTime, today)
            return (startTime < today && endTime > today) && localBookingData.status === BookingConfig.Confirmed;
        }
        return false;
    }, []);

    const checkCancelTime = useCallback((localBookingData) => {
        const today = new Date().getTime();
        const startTime = getTime(timeArr[localBookingData.slots[0] - 1], localBookingData.slotDate);
        const lessThirtyStartTime = new Date(startTime).setMinutes(new Date(startTime).getMinutes() - 30);
        console.log("start-------> ", lessThirtyStartTime, today)
        return (lessThirtyStartTime > today) && localBookingData.status === BookingConfig.Confirmed;
    }, []);

    const checkCompleteTime = useCallback((localBookingData) => {
        const today = new Date().getTime();
        const startTime = getTime(timeArr[localBookingData.slots[0] - 1], localBookingData.slotDate);
        console.log("start-------> ", startTime, today)
        return (startTime < today) && localBookingData.status === BookingConfig.Confirmed;
    }, []);

    const getAllBookings = useCallback(async (date) => {
        toggleDisablePage(true);
        toggleLoadingBookingList(true);
        const bookingResult = await getBookings(date);
        console.log("bookingResult ", bookingResult);

        toggleDisablePage(false);
        toggleLoadingBookingList(false);
        if (bookingResult.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.errorCode]);
            return;
        }
        if (bookingResult.data.data && bookingResult.data.data.errorCode) {
            toast.error(API_ERROR[bookingResult.data.data.errorCode]);
            return;
        }

        const localBookings = [];
        let bookingObj = {};
        if (bookingResult.data.data && bookingResult.data.data.length) {
            bookingResult.data.data.forEach(each => {
                
                if (each.type === 1) {
                    bookingObj = {};
                    bookingObj._id = each._id;
                    if (each.bookingForType === 1) {
                        bookingObj.name = each.customerRef.personalInfo.name;
                        bookingObj.profilePicture = each.customerRef.personalInfo.profilePicture;
                    } else {
                        bookingObj.name = each.bookingForName;
                        bookingObj.profilePicture = "";
                    }
                    if (each.serviceRef) {
                        bookingObj.className = each.serviceRef.title;
                    }
                    bookingObj.selected = false;
                    bookingObj.date = getFullDateFormat(new Date(each.date));
                    bookingObj.slotDate = each.date;
                    bookingObj.startTime = getStartTime(each.slots);
                    bookingObj.endTime = getEndTime(each.slots);
                    bookingObj.convenienceFee = each.paymentDetails.convenienceFee;
                    bookingObj.gst = each.paymentDetails.gst;
                    bookingObj.total = each.paymentDetails.total;
                    bookingObj.cost = each.paymentDetails.serviceFee;
                    bookingObj.status = each.status;
                    bookingObj.slots = each.slots;
                    bookingObj.isCancelable = checkCancelTime(bookingObj);
                    bookingObj.isCallJoinable = checkJoiningCallTime(bookingObj);
                    bookingObj.isCompletable = checkCompleteTime(bookingObj);
                    bookingObj.refundAmount = each.transactionDetails && each.transactionDetails.vendorDue ? each.transactionDetails.vendorDue : 0;
                    bookingObj.type = 1;

                    localBookings.push({ ...bookingObj });
                } else {
                    bookingObj = {};
                    bookingObj._id = each._id;
                    bookingObj.name = each.slots.length === timeArr.length - 1 ? 'Marked as Holiday' : 'Slot blocked for other reason';
                    bookingObj.selected = false;
                    bookingObj.startTime = getStartTime(each.slots);
                    bookingObj.endTime = getEndTime(each.slots);
                    bookingObj.slots = each.slots;
                    bookingObj.type = 2;

                    localBookings.push({ ...bookingObj });
                }
                
            });
        }
        updateBookings([...localBookings]);
    }, [checkCancelTime, checkJoiningCallTime, checkCompleteTime]);

    useEffect(() => {
        console.log("val ", selectedDate)
        getAllBookings(selectedDate);
    }, [selectedDate, getAllBookings]);

    const modifyOtp = (otpText, index) => {
        const localOtpArray = [...otpArray];
        const regex = /^-?\d+\.?\d*$/;
        localOtpArray[index] = otpText;
        if (regex.test(otpText) && document.getElementById(`otp${index+1}`)) {
        document.getElementById(`otp${index+1}`).focus();
        }
        updateOtpArray([...localOtpArray]);
    };

    const toggleBookingDisplay = (index) => {
        const localBookings = [...bookings];
        localBookings.forEach((each, ind) => {
            if (ind === index) {
                each.selected = !each.selected;
            } else {
                each.selected = false;
            }
        })
        updateBookings([...localBookings]);
    };

    const joinTwilioCall = (bookingDetails) => {
        if (checkJoiningCallTime(bookingDetails)) {
            props.history.push(`/video-call/${bookingDetails._id}`);
        } else {
            console.log("heererrer")
            toast.error(CALL_TIME_PASSED);
        }
    };

    const cancelBooking = async () => {
        console.log("cancel")
        if (checkCancelTime(cancelableObject)) {
            // api call
            toggleDisablePage(true);
            const cancelBookingResult = await cancelBook(cancelableObject._id);
            toggleDisablePage(false);
            if (cancelBookingResult.data.errorCode) {
                toast.error(API_ERROR[cancelBookingResult.data.errorCode]);
                return;
            }
            if (cancelBookingResult.data.data && cancelBookingResult.data.data.errorCode) {
                toast.error(API_ERROR[cancelBookingResult.data.data.errorCode]);
                return;
            }
            console.log("cancelBookingResult ", cancelBookingResult);
            toast.success(API_SUCCESS.CANCEL_SUCCESS);
            getAllBookings(selectedDate);
            toggleCancelModal(false);
            updateCancelableObject({});
        } else {
            toast.error(CANCEL_TIME_PASSED);
        }
    };

    const completeAppointment = async (bookingData) => {
        if (!checkCompleteTime(bookingData)) {
            toast.error(COMPLETE_TIME_PASSED);
            return;
        }
        toggleDisablePage(true);
        const result = await completeBooking(bookingData._id, {
            pin: Number(otpArray.join(""))
        });
        toggleDisablePage(false);
        if (result.data.errorCode) {
            toast.error(API_ERROR[result.data.errorCode]);
            return;
        }
        toast.success(API_SUCCESS.APPOINTMENT_COMPLETED);
        getAllBookings(selectedDate);
    };

    const validateTime = () => {
        const localLocationError = { ...locationDetailsError };
        console.log("locationDetails ", locationDetailsError)
        let noError = true;

        if (new Date(selectedFromDate) > new Date(selectedToDate)) {
            localLocationError.selectedFromDate = INVALID_FROM_TO_DATE;
            noError = false;
        } else {
            localLocationError.selectedFromDate = "";
        }

        updateLocationDetailsError({ ...localLocationError });
        return noError;
    };

    const updateDateError = () => {
        const localTimeError = {...locationDetailsError};
        localTimeError.selectedFromDate = "";
        updateLocationDetailsError({...localTimeError});
    };

    useEffect(() => {
        if (!displaySlotModal) {
            handleFromDateChange(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(0)).setSeconds(0)));
            handleToDateChange(new Date(new Date(new Date(new Date().setHours(0)).setMinutes(30)).setSeconds(0)));
        }
    }, [displaySlotModal]);

    const blockSlot = async (isHoliday) => {
        if (!validateTime()) {
            return;
        }
        const fromIndex = !isHoliday ? (timeArr.indexOf(formatAMPM(selectedFromDate)) + 1) : 1;
        const toIndex = !isHoliday ? (timeArr.indexOf(formatAMPM(selectedToDate))) : timeArr.length - 1;
        const localWorkingTimeSlots = [];
        
        if (fromIndex === toIndex) {
            localWorkingTimeSlots.push(fromIndex);
        } else {
            for (let i = fromIndex; i <= toIndex; i++) {
                localWorkingTimeSlots.push(i);
            }
        }
        const requestBody = {
            slots: localWorkingTimeSlots,
            date: getISOString(selectedDate)
        }
        toggleDisablePage(true);
        console.log("requestBody ", requestBody);
        // return;
        const result = await blockSlotAPI(requestBody);
        toggleDisablePage(false);
        if (result.data.errorCode) {
            toast.error(API_ERROR[result.data.errorCode]);
            return;
        }
        
        if (!isHoliday) {
            toggleSlotModal(false);
            toast.success(API_SUCCESS.SLOT_BLOCKED);
        } else {
            toggleHolidayModal(false);
            toast.success(API_SUCCESS.MARK_HOLIDAY);
        }
        
        getAllBookings(selectedDate);
    };

    const openUnblockModal = (localBooking) => {
        updateUnblockableObject({...localBooking});
        toggleUnblockModal(true);
    };
    
    const unblockSlot = async () => {
        const result = await unblockSlotAPI(unblockableObject._id);
        if (result.data.errorCode) {
            toast.error(API_ERROR[result.data.errorCode]);
            return;
        }
        toggleUnblockModal(false);
        toast.success(API_SUCCESS.SLOT_UNBLOCKED);
        updateUnblockableObject({});
        
        getAllBookings(selectedDate);
    };

    return (
        <div className={`booking-list row problem-area scheduler centered-for-max ${disablePage ? "pointer-none" : ""}`}>
            <CancelModal displayCancelModal={displayCancelModal}
                toggleCancelModal={toggleCancelModal} cancelBooking={cancelBooking} />
            <UnblockModal displayUnblockModal={displayUnblockModal}
                toggleUnblockModal={toggleUnblockModal} unblockSlot={unblockSlot} />
            <HolidayModal displayHolidayModal={displayHolidayModal}
                toggleHolidayModal={toggleHolidayModal} markAsHoliday={blockSlot} />
            <div className="col-md-6 col-12">
                <div className="col-12 categories-heading">
                    <h4 className={`mb-0`}>Scheduler</h4>
                </div>
                <div className="col-12 p-0">
                    <div className="row m-0">
                        <Calendar
                            onChange={onSelectedDateChange}
                            selectedDate={selectedDate}
                        />
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-12">
                <div className="col-12 categories-heading">
                    <h4 className={`mb-0`}>Bookings for {getFullDateFormat(new Date(selectedDate))}</h4>
                </div>
                <div className="col-12 p-0">
                    <div className="row professional-near-you-wrapper" style={{ margin: 0 }}>
                        {getTimestamp(selectedDate) >= getTimestamp(new Date()) ? 
                        <div className="col-12 p-0">
                            <div className="row" style={{ margin: 0 }}>
                                <div className="col-7 pl-0">
                                    <button type="button" className="btn btn-outline-primary btn-sm holiday-button" onClick={() => toggleHolidayModal(true)}>Mark as Holiday / Leave</button>
                                </div>
                                <div className="col-5 pr-0 text-right mb-3">
                                    <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => toggleSlotModal(true)}>Block Slots</button>
                                </div>
                            </div>
                        </div> : null}
                        {bookings.length ?
                            bookings.map((booking, index) => {
                                return <div className={`col-12 professional-wrapper ${index !== bookings.length - 1 ? 'mb-15' : ''}
                                `} key={index}>
                                    <div className="row service-row" style={{ margin: 0 }}>
                                        <div className="col-12 p-0">
                                            {booking.type === 1 ?
                                            <div className="booking-profile-image" onClick={() => booking.type === 1 ? toggleBookingDisplay(index) : () => {}}>
                                                {booking.profilePicture ?
                                                    <div style={{ backgroundImage: "url(" + booking.profilePicture + ")" }} className="background-image-professional" /> :
                                                    <div id="2" style={{ backgroundImage: "url(" + defaultImage + ")" }} className="background-image-professional" />}
                                            </div> : null}
                                            <div className={`booking-details-wrapper ${booking.type === 2 ? 'w-100' : ''}`} onClick={() => booking.type === 1 ? toggleBookingDisplay(index) : () => {}}>
                                                <div className="professional-name customer-name">{booking.name}
                                                   {booking.type === 1 ? !booking.selected ?
                                                        <i className="fa fa-caret-down" aria-hidden="true"></i> :
                                                        <i className="fa fa-caret-up" aria-hidden="true"></i> : <i className="fa fa-times" aria-hidden="true" onClick={() => openUnblockModal(booking)}></i>}
                                                </div>
                                                {booking.type === 1 ?
                                                <div className="professional-location">{booking.className}</div> : null}
                                                <div className="professional-time">{booking.startTime} - {booking.endTime}
                                                </div>
                                            </div>
                                            {booking.isCallJoinable ?
                                            <div className="col-12 p-0 text-center">
                                                <button type="button" className="btn btn-primary btn-sm mt-2 w-200px mb-3" onClick={() => joinTwilioCall(booking)}>Join</button>
                                            </div> : null}
                                        </div>
                                        {booking.selected ? <>
                                            {booking.isCompletable ?
                                            <div className="col-12 p-0 mt-3">
                                                <div className="row box-shadow-wrapper" style={{ margin: 0 }}>
                                                    <div className="col-12 pl-0 pr-0 booking-details-header text-center">
                                                        Enter Appointment Pin
                                                    </div>
                                                    <div className="col-12 pl-0 pr-0 mt-2">
                                                        <div className="row form-group login-otp-input-wrapper">
                                                            {otpArray.map((item, index) => {
                                                                return <div className="col-3 pl-0" key={index}>
                                                                    <input type="text" className="form-control" value={item} onChange={e => modifyOtp(e.target.value.replace(/\D/, ''), index)} maxLength={1} id={`otp${index}`} />
                                                                </div>
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 p-0 mt-3 text-center">
                                                        <button type="button" className="btn btn-primary btn-sm complete-appointment-button" disabled={(!otpArray[0].length || !otpArray[1].length || !otpArray[2].length || !otpArray[3].length)} onClick={() => completeAppointment(booking)}>Complete Appointment</button>
                                                    </div>
                                                </div>
                                            </div> : null}
                                            <div className="col-12 p-0 mt-3">
                                                <div className="row box-shadow-wrapper" style={{ margin: 0 }}>
                                                    <div className="col-12 pl-0 pr-0 booking-details-header">
                                                        Invoice
                                                    </div>
                                                    <div className="col-12 p-0">
                                                        <div className="row" style={{ margin: "5px 0" }}>
                                                            <div className="col-6 pl-0 fee-label">
                                                                Fee:
                                                            </div>
                                                            <div className="col-6 pr-0 text-right text-right fee-text">
                                                                Rs. {booking.cost.toFixed(2)}
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{ margin: "5px 0" }}>
                                                            <div className="col-6 pl-0 fee-label">
                                                                Convenience Fee:
                                                            </div>
                                                            <div className="col-6 pr-0 text-right fee-text">
                                                                Rs. {booking.convenienceFee.toFixed(2)}
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{ margin: "5px 0" }}>
                                                            <div className="col-6 pl-0 fee-label">
                                                                GST:
                                                            </div>
                                                            <div className="col-6 pr-0 text-right fee-text">
                                                                Rs. {booking.gst.toFixed(2)}
                                                            </div>
                                                        </div>
                                                        <div className="row fee-total-wrapper" style={{ margin: "5px 0" }}>
                                                            <div className="col-6 pl-0 fee-label fee-total-label">
                                                                Total:
                                                            </div>
                                                            <div className="col-6 pr-0 text-right fee-text">
                                                                Rs. {booking.total.toFixed(2)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {booking.status !== BookingConfig.Confirmed ?
                                            <div className="col-12 p-0 mt-3">
                                                <div className="row box-shadow-wrapper" style={{ margin: 0 }}>
                                                    <div className="col-12 pl-0 pr-0 booking-details-header">
                                                        Booking Status
                                                    </div>
                                                    {booking.status === BookingConfig.Completed ?
                                                    <div className="col-12 p-0 status-details-header">
                                                        The appointment has been completed
                                                    </div> : null}
                                                    {booking.status === BookingConfig.Failed ?
                                                    <div className="col-12 p-0 status-details-header">
                                                        The appointment has been failed
                                                    </div> : null}
                                                    {booking.status === BookingConfig.CanceledByCustomer ?
                                                    <div className="col-12 p-0 status-details-header">
                                                        The appointment has been cancelled by the customer
                                                        <div className="cancel-info">
                                                            You are eligible for a service fee of Rs {booking.refundAmount.toFixed(2)}
                                                        </div>
                                                        <div className="cancel-info">
                                                            According to the cancellation policy
                                                        </div>
                                                    
                                                    </div> : null}
                                                    {booking.status === BookingConfig.CanceledByVendor ?
                                                    <div className="col-12 p-0 status-details-header">
                                                        The appointment has been cancelled by you
                                                        <div className="cancel-info">
                                                            You are eligible for a service fee of Rs {booking.refundAmount.toFixed(2)}
                                                        </div>
                                                        <div className="cancel-info">
                                                            According to the cancellation policy
                                                        </div>
                                                    
                                                    </div> : null}
                                                    {booking.status === BookingConfig.Timedout ?
                                                    <div className="col-12 p-0 status-details-header">
                                                        The appointment has been timed out
                                                        <div className="cancel-info">
                                                            You are eligible for a service fee of Rs {booking.refundAmount.toFixed(2)}
                                                        </div>
                                                        <div className="cancel-info">
                                                            According to the cancellation policy
                                                        </div>
                                                    
                                                    </div> : null}
                                                </div>
                                            </div> : null}
                                            {booking.isCancelable ?
                                            <>
                                                <div className="col-12 p-0 mt-3 text-center font-12">
                                                    Cancel before 30 mins
                                                </div>
                                                <div className="col-12 p-0 mt-1 text-center join-cancel-button-wrapper">
                                                    <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => {updateCancelableObject({...booking}); toggleCancelModal(true)}}>Cancel</button>
                                                </div>
                                            </> : null}
                                        </> : null}
                                    </div>
                                </div>
                            })
                            : <div className="text-center well col-12 color-black">
                                {!loadingBookingList ? 'No Bookings Available!' : 'loading...'}
                    </div>}
                    </div>
                </div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className={`modal login-modal ask-question-modal fade ${displaySlotModal ? 'show show-login-modal' : ''}`} id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Block Slots</h5>
                            </div>
                            <div className="modal-body">
                                <div className="row" style={{ margin: 0 }}>
                                    <div className="col-12 p-0">
                                        <div className="row" style={{ margin: 0 }}>
                                            <div className="col-6 pl-0 mb-3">
                                                <label for="exampleFormControlInput1" className="form-label">Time From</label><br />
                                                <TimePicker value={selectedFromDate} className={`${locationDetailsError.selectedFromDate ? 'timepicker-error-border' : ''}`} onChange={handleFromDateChange} minutesStep={30} onClose={updateDateError} />
                                                {locationDetailsError.selectedFromDate ?
                                                    <div className="input-error">{locationDetailsError.selectedFromDate}</div> : null}
                                            </div>
                                            <div className="col-6 pr-0 mb-3">
                                                <label for="exampleFormControlInput1" className="form-label">Time To</label><br />
                                                <TimePicker value={selectedToDate} className={`${locationDetailsError.selectedFromDate ? 'timepicker-error-border' : ''}`} onChange={handleToDateChange} onClose={updateDateError} minutesStep={30} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 login-button">
                                    <button type="button" className="btn btn-primary" onClick={() => blockSlot(false)}>Submit</button>
                                    <span className="span-link" onClick={() => toggleSlotModal(false)}>Cancel</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </MuiPickersUtilsProvider>
            </div>
        </div>
    );
}

export default Scheduler;
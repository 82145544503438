export const REQUIRED_ERROR = "This field is required";
export const VALID_PHONE_ERROR = "Please enter valid phone number";
export const INVALID_FROM_TO_DATE = "Please choose valid from and to date";
export const CHOOSE_ERROR = "Please select atleast one day of the week";
export const INVALID_EMAIL = "Please enter a valid email";
export const INVALID_WEBSITE = "Please enter a valid URL";
export const INVALID_MONTH = "Please enter a valid month (0-11)";
export const CHOOSE_CATEGORY_ERROR = "Please select atleast one category";
export const INVALID_LOCATION = "Please choose a valid location from the dropdown";
export const CALL_TIME_PASSED = "You cannot join this call. It is already end";
export const CANCEL_TIME_PASSED = "You cannot cancel this call";
export const COMPLETE_TIME_PASSED = "You cannot complete this call";
export const API_ERROR = {
   "400":"API Validation error. Please add correct input.",
   "401":"Error while uploading",
   "403":"Access denied",
   "405":"You don't have access",
   "406":"Image not found",
   "501":"Invalid file type. Please upload correct file type.",
   "502":"You don't have access",
   "1000":"Session expired",
   "1001":"Invalid OTP. Please enter correct OTP",
   "1002":"OTP has been expired. Please generate another OTP",
   "1005":"Phone number not found",
   "1200":"Global config not found",
   "1301":"Professional category not found",
   "1400":"Professional email already exists",
   "1401":"Professional not found",
   "1402":"Professional has been removed",
   "1403":"Professional has been suspended",
   "1501":"Organizer not found",
   "1503":"Organizer has been blocked",
   "1504":"Organizer has been suspended",
   "1505":"Organizer has been deleted",
   "1600":"Service already present on same time slot",
   "1601":"Slot already booked",
   "1602":"Active booking present for this service, you cannot remove it",
   "1701":"Language not found",
   "1801":"Problem not found",
   "1901":"Problem not found",
   "2001":"FAQ not found",
   "2500":"Booking pin doesn't match. Please enter the correct pin",
   "2501":"Booking is not confirmed",
   "2502":"Booking is not pending",
   "2505":"Location tagged with services. you cannot update/ remove this location",
   "2506":"Location title already present. Please add a different location title."
};
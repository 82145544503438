// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import baseUrl from './api.util';
// import ApiErrorMessage from './api.errorMessage';
import { v4 as uuidv4 } from "uuid";
import { DEVICE_ID } from "../config/local-storage-keys";
import {actions} from './../store/ducks/auth.duck';
import { toast } from 'react-toastify';
import {
  API_ERROR
} from "../config/error-message";
const deviceId = () => {
  if (localStorage.getItem(DEVICE_ID)) {
    return localStorage.getItem(DEVICE_ID);
  } else {
    localStorage.setItem(DEVICE_ID, uuidv4());
    return localStorage.getItem(DEVICE_ID);
  }
};

const getToken = () => {
  let authToken = localStorage.getItem('authTokenPCVendorWeb');
  const token = authToken? authToken: "";
  return token;
};

axios.defaults.baseURL = baseUrl;
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["x-auth-deviceid"] = deviceId();
axios.defaults.headers.common["x-auth-devicetype"] = 3;

export const setupAxios = (store) => {

  // Add a request interceptor
  axios.interceptors.request.use(
    function(config) {
      // Do something before request is sent
      console.log('config', config);
      delete config.headers.Authorization;
      const token =getToken();
      console.log(token);
      if(token){
        config.headers["x-auth-token"] = token;
      }
      return config;
    },
    function(error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function(response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      if (!response.data.success) {
        if (response.data.errorCode === 1000) {
          if (localStorage.getItem('authTokenPCVendorWeb')) {
            toast.error(API_ERROR[response.data.errorCode]);
            store.dispatch(actions.logout());
          }
        }
        Promise.reject();
      }
      return response;
    },
    function(error) {
      console.log('error', error);
      if (error.response) {
        // Request made and server responded
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);

      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );
};
export default axios;
